import { Component, OnInit } from '@angular/core';
import { Action, Link, Menu } from '~/app/open-age/core/structures';
import { NavService } from '../../services';
import { UxService } from '../../services/ux.service';

@Component({
  selector: 'app-context-bar',
  templateUrl: './context-bar.component.html',
  styleUrls: ['./context-bar.component.css'],
})
export class ContextBarComponent implements OnInit {

  breadcrumb: Link[] = [];
  contextMenu: Menu;

  constructor(
    private uxService: UxService,
    private navService: NavService
  ) {
    this.uxService.contextMenuChanges.subscribe((menu) => {
      this.contextMenu = menu;
    });

    this.navService.breadcrumbChanges.subscribe((links) => {
      this.breadcrumb = links || [];

      if (this.breadcrumb.length > 0) {
        this.breadcrumb.forEach((i) => i.isActive = false);
        const current = this.breadcrumb[this.breadcrumb.length - 1];
        current.isActive = true;
        this.navService.setTitle(current.title);
      }
    });
  }

  ngOnInit() {
  }

  back() {
    this.navService.back();
  }

  toggle(item: Action) {
    item.value = !item.value;
    item.event(item.value);
  }
}

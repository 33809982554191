import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(value: string): string {
    console.log(value);
    value = value || "";
    value = value.trim();
    if (!value) return '';

    // Remove any non-digit characters
    const cleaned = value.replace(/\D/g, '');

    // Format as (123) 465-6789
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    // If the value does not match the expected format, return it as is
    return value;
  }

}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatSelectModule
} from '@angular/material';
import { MatCardModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { CurrentRoleComponent } from './current-role/current-role.component';
import { FacebookLoginButtonComponent } from './facebook-login-button/facebook-login-button.component';
import { GoogleLoginButtonComponent } from './google-login-button/google-login-button.component';
import { LinkedinLoginButtonComponent } from './linkedin-login-button/linkedin-login-button.component';
import { OtpLoginComponent } from './otp-login/otp-login.component';
import { PasswordLoginComponent } from './password-login/password-login.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { RoleListComponent } from './role-list/role-list.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { TwitterLoginButtonComponent } from './twitter-login-button/twitter-login-button.component';

const components = [
  PasswordLoginComponent,
  OtpLoginComponent,
  SignUpComponent,
  PasswordResetComponent,
  FacebookLoginButtonComponent,
  GoogleLoginButtonComponent,
  TwitterLoginButtonComponent,
  LinkedinLoginButtonComponent,
  CurrentRoleComponent,
  RoleListComponent
];
const thirdPartyModules = [
  MatCardModule,
  MatButtonModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatSelectModule,
  MatCheckboxModule
];
const services = [];
const guards = [];
const pipes = [];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    ...thirdPartyModules,
  ],
  declarations: [...components, ...pipes],
  exports: [...components, ...pipes],
  providers: [...services, ...guards],
  entryComponents: [
  ]
})
export class OaAuthModule { }

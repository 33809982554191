import { Component, ErrorHandler, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Project } from 'src/app/open-age/gateway/models/project.model';

import { ProjectService } from 'src/app/open-age/gateway/services/project.service';
import { PagerModel } from '~/app/open-age/core/structures';

export class ProjectListBaseComponent extends PagerModel<Project> implements OnInit, OnChanges {

  @Input()
  selectedStatus: string;

  @Output()
  selected: EventEmitter<Project> = new EventEmitter();

  @Output()
  count: EventEmitter<number> = new EventEmitter();

  project: Project;

  selectedCode: string;
  selectedName: string;

  constructor(
    api: ProjectService,
    errorHandler: ErrorHandler
  ) {
    super({
      api, errorHandler,
      filters: ['status', 'code', 'name']
    });
  }

  ngOnInit() {
    this.refresh();
  }

  ngOnChanges() {
    this.refresh();
  }

  onSelect(project: Project) {
    this.project = project;
    this.selected.emit(this.project);
  }

  refresh() {

    if (this.selectedStatus) {
      this.filters.properties['status'] = this.selectedStatus;
    }

    if (this.selectedCode) {
      this.filters.properties['code'] = this.selectedCode;
    }

    if (this.selectedName) {
      this.filters.properties['name'] = this.selectedName;
    }

    this.fetch();
  }

}

import { Component, Input, OnInit } from '@angular/core';

import { UxService } from '~/app/core/services';
import { IssueDetailBaseComponent } from '~/app/open-age/gateway/components/task-detail.base.component';
import { TaskService } from '~/app/open-age/gateway/services';

@Component({
  selector: 'gateway-task-summary',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.css']
})
export class TaskComponent extends IssueDetailBaseComponent {

  @Input()
  view = 'slides';

  constructor(
    uxService: UxService,
    issueService: TaskService
  ) {
    super(uxService, issueService);
  }

  getData() {
    return `Work Order Number: ${this.properties.code}, type: ${this.properties.type}`;
  }

}

import { ModelBase } from '../../core/models/model-base.model';
import { Calendar, Category } from './calendar.model';
import { User } from './user.model';

export class Organization extends ModelBase {
  name: string;
  timezoneOffset: Number
  meta: any;
  users: {
    user: User,
    calendars: {
      category: Category,
      calendar: Calendar,
      isActive: Boolean
    }[],
    grouped: any
  }[]


  constructor(obj?: any) {
    super();

    if (!obj) {
      return;
    }

    this.id = obj.id;
    this.code = obj.code;
    this.name = obj.name;
    this.timezoneOffset = Number(obj.timezoneOffset);
    this.status = obj.status;
    this.timeStamp = obj.timeStamp;
    this.meta = obj.meta || {}
    this.users = []

    if (obj.users) {
      for (const item of obj.users) {
        let calendars = []
        let grouped = {}
        if (item.calendars) {
          for (const calendarItem of item.calendars) {
            calendars.push({
              category: new Category(calendarItem.category),
              calendar: new Calendar(calendarItem.calendar),
              isActive: calendarItem.isActive
            })
            if (grouped[calendarItem.category.name] && grouped[calendarItem.category.name].calendars) {
              grouped[calendarItem.category.name].calendars.push({
                category: new Category(calendarItem.category),
                calendar: new Calendar(calendarItem.calendar),
                isActive: calendarItem.isActive,
                wasActive: calendarItem.isActive
              })
            } else {
              grouped[calendarItem.category.name] = {
                name: calendarItem.category.name,
                calendars: [{
                  category: new Category(calendarItem.category),
                  calendar: new Calendar(calendarItem.calendar),
                  isActive: calendarItem.isActive,
                  wasActive: calendarItem.isActive
                }]
              }
            }
          }
        }
        this.users.push({
          user: new User(item.user),
          calendars: calendars,
          grouped: grouped
        })
      }
    }

  }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { RoleService } from '~/app/open-age/core/services';
import { ReportParam } from '~/app/open-age/insight/models';

import * as moment from 'moment';
import { UxService } from '~/app/core/services';
import { Employee } from '~/app/open-age/directory/models';

declare var $: any;

@Component({
  selector: 'app-query-builder',
  templateUrl: './query-builder.component.html',
  styleUrls: ['./query-builder.component.css']
})
export class QueryBuilderComponent implements OnInit, OnChanges {

  @Input()
  filters: any[] = [];

  mainFilters: ReportParam[];
  otherFilters: ReportParam[];
  allFilters: ReportParam[];

  @Input()
  options: {
    labels?: {
      apply?: string
    }
  };

  @Output()
  apply: EventEmitter<any> = new EventEmitter();

  @Output()
  reset: EventEmitter<any> = new EventEmitter();

  isReset = false;
  showMore = false;

  // filter objects
  selectedEmployeeNo: string;
  selectedDepartment: string;
  selectedDepartmentId: string;
  selectedName: string;
  selectedFatherName: string;
  selectedCode: string;
  selectedType: string;
  selectedEmployeeType: string;
  selectedEmployeeId: string;
  selectedEmployee: string;
  selectedDesignation: string;
  selectedDesignationId: string;
  selectedDivision: string;
  selectedDivisionId: string;
  selectedCourse: string;
  selectedBatch: string;
  selectedContractor: string;
  selectedContractorId: string;
  selectedClocked: string;
  selectedCheckIn: string;
  selectedCheckOut: string;
  selectedMoreThen: number;
  selectedLessThen: number;
  selectedShiftType: string;
  selectedStatus: string;
  selectedLeaveStatus: string;
  selectedFromDate: Date;
  selectedToDate: Date;
  selectedDate: Date;
  selectedMonth: Date;

  // TODO: add filters here;

  constructor(
    public auth: RoleService,
    public uxService: UxService
  ) {
  }

  ngOnInit() {
    this.extract();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.extract();
  }

  onFromDateSelect(item: Date) {
    this.selectedFromDate = item;
  }

  onDateSelect(item: Date) {
    this.selectedDate = item;
  }

  onMonthSelect(item: Date) {
    this.selectedMonth = item;
  }

  onToDateSelect(item: Date) {
    item.setDate(item.getDate() + 1);  // add a day
    this.selectedToDate = item;
  }

  onNameSelect($event) {
    this.selectedName = $event.target.value;
  }

  onFatherNameSelect($event) {
    this.selectedFatherName = $event.target.value;
  }

  onCodeSelect($event) {
    this.selectedCode = $event.target.value;
  }

  onTypeSelect($event) {
    this.selectedType = $event;
  }

  onEmployeeIdSelect($event) {
    if ($event.id) {
      this.selectedEmployeeId = $event.id.toString();
    }
  }

  onEmployeeSelect($event) {
    if ($event.code) {
      this.selectedEmployee = $event.code.toString();
    }
  }

  onEmployeeTypeSelect($event) {
    this.selectedEmployeeType = $event;
  }

  onDesignationSelect($event) {
    this.selectedDesignation = $event;
  }
  onDesignationIdSelect($event) {
    this.selectedDesignationId = $event;
  }

  onDivisionSelect($event) {
    this.selectedDivision = $event;
  }
  onDivisionIdSelect($event) {
    this.selectedDivisionId = $event;
  }

  onBatchSelect($event) {
    this.selectedBatch = $event;
  }

  onCourseSelect($event) {
    this.selectedCourse = $event;
  }

  onContractorSelect($event) {
    this.selectedContractor = $event;
  }
  onContractorSelectId($event) {
    this.selectedContractorId = $event;
  }

  onClockedSelect($event) {
    this.selectedClocked = $event;
  }

  onCheckInSelect($event) {
    this.selectedCheckIn = $event;
  }

  onCheckOutSelect($event) {
    this.selectedCheckOut = $event;
  }

  onMoreThenSelect($event) {
    this.selectedMoreThen = $event.target.value;
  }

  onLessThenSelect($event) {
    this.selectedLessThen = $event.target.value;
  }

  onDepartmentSelect($event) {
    this.selectedDepartment = $event;
  }

  onDepartmentIdSelect($event) {
    this.selectedDepartmentId = $event;
  }

  onLeaveStatusSelect($event) {
    this.selectedLeaveStatus = $event;
  }

  onStatusSelect($event) {
    this.selectedStatus = $event;
  }

  onShiftTypeSelect($event) {
    this.selectedShiftType = $event;
  }

  onEmployeeNoSelect($event) {
    this.selectedEmployeeNo = $event;
  }
  onReset() {
    this.isReset = true;
    this.selectedEmployeeNo = null;
    this.selectedDate = null;
    this.selectedMonth = null;
    this.selectedFromDate = null;
    this.selectedToDate = null;
    this.selectedDepartment = null;
    this.selectedDepartmentId = null;
    this.selectedStatus = null;
    this.selectedLeaveStatus = null;
    this.selectedShiftType = null;
    this.selectedDesignation = null;
    this.selectedDesignationId = null;
    this.selectedDivision = null;
    this.selectedDivisionId = null;
    this.selectedBatch = null;
    this.selectedCourse = null;
    this.selectedContractor = null;
    this.selectedContractorId = null;
    this.selectedClocked = null;
    this.selectedCheckIn = null;
    this.selectedCheckOut = null;
    this.selectedMoreThen = null;
    this.selectedLessThen = null;
    this.selectedName = null;
    this.selectedFatherName = null;
    this.selectedCode = null;
    this.selectedType = null;
    this.selectedEmployeeType = null;
    this.selectedEmployeeId = null;
    this.selectedEmployee = null;

    this.reset.emit();
    const this_new = this;
    setTimeout(() => {
      this_new.isReset = !this_new.isReset;
    }, 100);
  }

  getValues() {
    const values: ReportParam[] = [];
    this.addValue(values, this.selectedFromDate, 'date', 'date-from', 'From Date');
    this.addValue(values, this.selectedToDate, 'date', 'date-to', 'To Date');
    this.addValue(values, this.selectedDate, 'date', 'date', 'Date');
    this.addValue(values, this.selectedMonth, 'month', 'month', 'Month');
    this.addValue(values, this.selectedEmployeeNo, this.selectedEmployeeNo, 'employee-no', 'Employee No');
    this.addValue(values, this.selectedDepartment, this.selectedDepartment, 'department', 'Department');
    this.addValue(values, this.selectedDepartmentId, this.selectedDepartmentId, 'department-id', 'Department');
    this.addValue(values, this.selectedName, this.selectedName, 'name', 'Name');
    this.addValue(values, this.selectedFatherName, this.selectedFatherName, 'father-name', 'Father Name');
    this.addValue(values, this.selectedCode, this.selectedCode, 'code', 'Code');
    this.addValue(values, this.selectedType, this.selectedType, 'type', 'User Type');
    this.addValue(values, this.selectedEmployeeType, this.selectedEmployeeType, 'employee-type', 'Employee Type');
    this.addValue(values, this.selectedDesignation, this.selectedDesignation, 'designation', 'Designation');
    this.addValue(values, this.selectedDesignationId, this.selectedDesignationId, 'designation-id', 'Designation Id');
    this.addValue(values, this.selectedDivision, this.selectedDivision, 'division', 'Division');
    this.addValue(values, this.selectedDivisionId, this.selectedDivisionId, 'division-id', 'Division Id');
    this.addValue(values, this.selectedCourse, this.selectedCourse, 'course', 'Course');
    this.addValue(values, this.selectedBatch, this.selectedBatch, 'batch', 'Batch');
    this.addValue(values, this.selectedContractor, this.selectedContractor, 'contractor', 'Contractor');
    this.addValue(values, this.selectedEmployeeId, this.selectedEmployeeId, 'supervisor-id', 'Supervisor');
    this.addValue(values, this.selectedEmployee, this.selectedEmployee, 'supervisor', 'Supervisor');
    this.addValue(values, this.selectedContractorId, this.selectedContractorId, 'contractor-id', 'Contractor Id');
    this.addValue(values, this.selectedClocked, this.selectedClocked, 'clocked', 'Clocked');
    this.addValue(values, this.selectedCheckIn, this.selectedCheckIn, 'checkIn', 'Check In');
    this.addValue(values, this.selectedCheckOut, this.selectedCheckOut, 'checkOut', 'Check Out');
    this.addValue(values, this.selectedMoreThen, this.selectedMoreThen, 'moreThen', 'More Then');
    this.addValue(values, this.selectedLessThen, this.selectedLessThen, 'lessThen', 'Less Then');
    this.addValue(values, this.selectedStatus, this.selectedStatus, 'status', 'Status');
    this.addValue(values, this.selectedLeaveStatus, this.selectedLeaveStatus, 'leaveStatus', 'Leave Status');
    this.addValue(values, this.selectedShiftType, this.selectedShiftType, 'shiftType', 'Shift Type');
    // TODO: add values here

    return values;
  }

  extract() {
    this.options = this.options || {};
    this.options.labels = this.options.labels || {};
    this.options.labels.apply = this.options.labels.apply || 'Apply';

    this.mainFilters = [];
    this.otherFilters = [];
    this.allFilters = [];
    this.filters.forEach((item) => {
      const queryItem = new ReportParam();
      queryItem.key = item.key || item.code || item;
      queryItem.group = item.group || 'main';
      queryItem.required = item.required || false;
      queryItem.message = item.message;
      queryItem.label = item.label || item.name;
      if (queryItem.group === 'main') {
        this.mainFilters.push(queryItem);
      } else {
        this.otherFilters.push(queryItem);
      }

      this.allFilters.push(queryItem);
    });
  }

  onApply() {
    const values = this.getValues();

    for (const item of this.mainFilters) {
      if (item.required && !values.find((i) => i.key === item.key)) {
        this.uxService.handleError(item.message || 'Missing required fields');
        return;
      }
    }
    this.apply.emit(values);
  }

  addValue(values: ReportParam[], value: any, display: any, key: string, label: string) {
    if (!value) {
      return;
    }

    const filter = this.allFilters.find((i) => i.key === key);

    if (display === 'date') {
      display = moment(value).format('DD-MM-YYYY');
    } else {
      display = `${display}`;
    }

    values.push({
      label: filter.label || label,
      key,
      value,
      valueLabel: display
    });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatChipsModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatTableModule,
  MatTooltipModule
} from '@angular/material';

import { SharedModule } from 'src/app/shared/shared.module';
import { OaDriveModule } from '../../open-age-ng/drive/oa-drive.module';
import { GatewayModule } from '../../open-age/gateway/gateway.module';
import { OaCommonModule } from '../common/oa-common.module';
import { OaDirectoryModule } from '../directory/oa-directory.module';
import { MyTasksComponent } from './my-tasks/my-tasks.component';
import { NewProjectDialogComponent } from './new-project-dialog/new-project-dialog.component';
import { NewReleaseDialogComponent } from './new-release-dialog/new-release-dialog.component';
import { NewSprintDialogComponent } from './new-sprint-dialog/new-sprint-dialog.component';
import { NewTaskComponent } from './new-task/new-task.component';
import { ProjectButtonComponent } from './project-button/project-button.component';
import { ProjectListComponent } from './project-list/project-list.component';
import { ProjectSummaryComponent } from './project-summary/project-summary.component';
import { ReleaseButtonComponent } from './release-button/release-button.component';
import { ReleaseListComponent } from './release-list/release-list.component';
import { ReleaseOngoingListComponent } from './release-ongoing-list/release-ongoing-list.component';
import { ReleaseSummaryExpandableComponent } from './release-summary-expandable/release-summary-expandable.component';
import { ReleaseSummaryComponent } from './release-summary/release-summary.component';
import { ReleaseTimelineComponent } from './release-timeline/release-timeline.component';
import { TaskListComponent } from './task-list/task-list.component';
import { TaskProcessComponent } from './task-process/task-process.component';
import { TaskStatusBarComponent } from './task-status-bar/task-status-bar.component';
import { TaskSyncButtonComponent } from './task-sync-button/task-sync-button.component';
import { TaskComponent } from './task/task.component';
import { TeamComponent } from './team/team.component';
import { WorkflowProgressComponent } from './workflow-progress/workflow-progress.component';
import { WorkflowStateDetailComponent } from './workflow-state-detail/workflow-state-detail.component';
import { WorkflowStateSummaryComponent } from './workflow-state-summary/workflow-state-summary.component';

const components = [
  ProjectListComponent,
  ProjectSummaryComponent,
  NewProjectDialogComponent,
  ProjectButtonComponent,
  MyTasksComponent,
  TeamComponent,
  ReleaseListComponent,
  ReleaseSummaryComponent,
  TaskListComponent,
  TaskSyncButtonComponent,
  NewReleaseDialogComponent,
  ReleaseTimelineComponent,
  ReleaseSummaryExpandableComponent,
  ReleaseButtonComponent,
  ReleaseOngoingListComponent,
  WorkflowStateDetailComponent,
  WorkflowProgressComponent,
  WorkflowStateSummaryComponent,
  TaskComponent,
  NewTaskComponent,
  TaskProcessComponent,
  TaskStatusBarComponent,
  NewSprintDialogComponent
];
const thirdPartyModules = [
  MatCardModule,
  MatExpansionModule,
  MatButtonModule,
  MatFormFieldModule,
  MatIconModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatInputModule,
  MatTooltipModule,
  MatTableModule
];
const services = [];
const guards = [];
const pipes = [];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDialogModule,
    GatewayModule,
    SharedModule,
    OaDirectoryModule,
    OaDriveModule,
    OaCommonModule,
    ...thirdPartyModules,
  ],
  declarations: [...components, ...pipes],
  exports: [...components, ...pipes],
  providers: [...services, ...guards],
  entryComponents: [
    NewReleaseDialogComponent,
    NewProjectDialogComponent,
    NewSprintDialogComponent
  ]
})
export class OaGatewayModule { }

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { UxService } from 'src/app/core/services/ux.service';
import { Tenant } from 'src/app/open-age/directory/models';

@Component({
  selector: 'code-dialog',
  templateUrl: './code-dialog.component.html',
  styleUrls: ['./code-dialog.component.css']
})
export class CodeDialogComponent implements OnInit {

  title = '';
  code = '';

  type: 'text' | 'otp' = 'text';

  otp = {
    char_1: '',
    char_2: '',
    char_3: '',
    char_4: '',
    char_5: '',
    char_6: '',
  };

  constructor(
    public dialog: MatDialogRef<CodeDialogComponent>,
    private uxService: UxService
  ) { }

  ngOnInit() {
  }

  proceed() {
    if (this.type === 'otp') {
      this.code = `${this.otp.char_1}${this.otp.char_2}${this.otp.char_3}${this.otp.char_4}${this.otp.char_5}${this.otp.char_6}`;
    }
    if (!this.code) {
      return this.uxService.handleError('Code is required');
    }

    this.dialog.close(this.code);
  }

  cancel() {
    this.dialog.close();
  }

  focusToNext(e: HTMLInputElement, nextEle?: HTMLInputElement) {
    if (e.value && nextEle && e.maxLength === e.value.length) {
      nextEle.select();
      nextEle.focus();
    }
  }

}

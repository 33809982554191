import { Component, ErrorHandler, Input, OnInit } from '@angular/core';
import { CompensationsDetailBaseComponent } from '~/app/open-age/car/components/compensations-detail-base.component';
import { CompensationsService } from '~/app/open-age/car/services/compensations.service';

@Component({
  selector: 'car-compensations-detail',
  templateUrl: './compensations-detail.component.html',
  styleUrls: ['./compensations-detail.component.css']
})
export class CompensationsDetailComponent extends CompensationsDetailBaseComponent {

  constructor(
    service: CompensationsService,
    errorHandler: ErrorHandler
  ) {
    super(service, errorHandler);
  }

  ngOnInit() {
    super.ngOnInit();
  }

}

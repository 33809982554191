import { Component, ErrorHandler, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { JobService } from 'src/app/open-age/send-it/services';
import { PagerModel } from '~/app/open-age/core/structures';
import { ReportArea } from '../models';
import { ReportType } from '../models/report-type.model';
import { ReportTypeService } from '../services/report-type.service';

export class ReportTypeListBaseComponent extends PagerModel<ReportType> implements OnInit, OnChanges {

  @Input()
  area: string | ReportArea;

  @Input()
  view: 'table' | 'list' | 'grid' = 'table';

  constructor(
    api: ReportTypeService,
    errorHandler: ErrorHandler
  ) {
    super({ api, errorHandler, filters: ['area'] });
  }
  ngOnInit(): void {
    this.refresh();
  }

  ngOnChanges(): void {
    this.refresh();
  }

  refresh() {
    if (!this.area) {
      return;
    }

    if (typeof this.area === 'string') {
      this.filters.properties['area'].value = this.area;
    } else {
      this.filters.properties['area'].value = this.area.code;
    }
    this.fetch();
  }

}

import { Pic } from '../../core/models';

export class Profile {
    prefix: string;
    firstName: string;
    middleName: string;
    lastName: string;
    suffix: string;
    gender: string;
    title: string;

    pic: Pic;
    dob: Date | string;

    constructor(obj?: any) {

        if (!obj) {
            return;
        }
        this.prefix = obj.prefix;
        this.firstName = obj.firstName;
        this.middleName = obj.middleName;
        this.lastName = obj.lastName;
        this.suffix = obj.suffix;
        this.gender = obj.gender;
        this.dob = obj.dob;
        this.title = obj.title;
    }
}

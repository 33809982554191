import { NavigationExtras, Params } from '@angular/router';

export class Link {
  index = 0;
  parent: Link;
  code: string;
  name: string;
  url: string;
  activeUrl: string;
  target: string;
  title: string;
  icon: any;
  isActive = false;
  path: string;
  routerLink: string[];
  queryParams: Params;
  current: Link;
  items: Link[];
  permissions: string[];

  constructor(obj?: {
    index?: number,
    code?: string,
    name?: string,
    title?: string,
    icon?: any,
    isActive?: boolean,
    url?: string,
    activeUrl?: string,
    target?: string,
    path?: string,
    routerLink?: string[],
    queryParams?: Params,
    items?: any[],
    permissions?: string[]
  }) {
    if (!obj) { return; }
    this.index = obj.index;
    this.code = obj.code;
    this.name = obj.name;
    this.title = obj.title;

    this.icon = {};

    if (typeof obj.icon === 'string') {
      if (obj.icon.startsWith('http')) {
        this.icon.url = obj.icon;
      } else if (obj.icon.startsWith('fa')) {
        this.icon.fa = obj.icon;
      } else {
        this.icon.mat = obj.icon;
      }
    } else if (obj.icon) {
      this.icon = obj.icon;
    }

    this.isActive = obj.isActive;
    this.url = obj.url;
    this.activeUrl = obj.activeUrl;
    this.target = obj.target;

    this.queryParams = obj.queryParams || {};

    this.path = obj.path;
    if (obj.routerLink && obj.routerLink.length) {
      this.routerLink = [];
      let path = '';
      for (let index = 0; index < obj.routerLink.length; index++) {

        let item = obj.routerLink[index];
        if (index === (obj.routerLink.length - 1) && item.indexOf('?') !== -1) {
          this.queryParams = this.queryParams || {};

          const parts = item.split('?');

          item = parts[0];

          parts[1].split('&').forEach((p) => {
            const query = p.split('=');
            this.queryParams[query[0]] = query[1];
          });
        }
        path = item.startsWith('/') ? `${path}${item}` : `${path}/${item}`;
        this.routerLink.push(item);
      }
      this.path = path;

    }
    this.items = [];

    if (obj.items && obj.items.length) {
      this.items = obj.items.map((i) => {
        const item = new Link(i);
        i.parent = this;
        return item;
      });
    }
    this.permissions = obj.permissions || [];
  }
}

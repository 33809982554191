import { Component, OnInit } from '@angular/core';
import { UxService } from '~/app/core/services/ux.service';
import { FolderListBaseComponent } from '~/app/open-age/drive/components/folder-list-base.component';
import { FolderService } from '~/app/open-age/drive/services';

@Component({
  selector: 'drive-folder-list',
  templateUrl: './folder-list.component.html',
  styleUrls: ['./folder-list.component.css']
})
export class FolderListComponent extends FolderListBaseComponent {

  newFolder: boolean;

  allDeleted: boolean;

  constructor(
    service: FolderService,
    uxService: UxService
  ) {
    super(service, uxService);
  }

  onFolderSelect($event, folder) {
    folder.isDeleted = $event.checked;
    this.checkForSelectedAll();
  }

  onFolderCreate(folder) {
    this.newFolder = false;
  }

  checkForSelectedAll() {
    this.allDeleted = this.items.every((item) => item.isDeleted);
  }

  selectedAll(value: boolean) {
    this.items.forEach((item) => item.isDeleted = value);
    this.allDeleted = value;
  }

  delete() {
    const folders = this.items.filter((item) => item.isDeleted);

    if (!folders.length) {
      return;
    }

    this.bulkRemove(folders);
  }

}

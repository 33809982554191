import { ErrorHandler, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { PagerBaseComponent } from '~/app/open-age/core/structures';
import { ComponentType } from '../models/component-type.model';
import { ComponentTypeService } from '../services';

export class ComponentTypeListBaseComponent extends PagerBaseComponent<ComponentType> implements OnInit, OnChanges, OnDestroy {

  @Input()
  readonly = false;

  @Input()
  sort = 'code';

  @Input()
  codeFilter = '';

  @Input()
  statusFilter = '';

  @Input()
  tempFilter = false;

  constructor(
    service: ComponentTypeService,
    errorHandler: ErrorHandler
  ) {
    super({
      api: service,
      errorHandler,
      pageOptions: { limit: 10 },
      filters: ['code', 'status'],
    });
  }

  ngOnInit() {
    this.search();
  }

  ngOnChanges(): void {
    this.search();
  }

  search() {

    this.filters.properties['code'].value = this.codeFilter;
    this.filters.properties['status'].value = this.statusFilter;
    this.currentPageNo = 1;

    this.fetch();
  }

  ngOnDestroy(): void {
  }

}

import { Component, ErrorHandler, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { Message } from 'src/app/open-age/send-it/models';
import { MessageService } from 'src/app/open-age/send-it/services';
import { PagerModel } from '~/app/open-age/core/structures';
import { Conversation } from '../models/conversation.model';
import { ConversationService } from '../services/conversation.service';

export class ConversationDetailBaseComponent extends PagerModel<Message> implements OnInit, OnChanges {

  @Input()
  conversation: Conversation;

  @Input()
  unread = false;

  @Input()
  sort = 'date';

  selectedSubject: string;

  constructor(
    private conversationService: ConversationService,
    private api: MessageService,
    errorHandler: ErrorHandler
  ) {
    super({
      api,
      errorHandler,
      filters: ['conversationId', 'subject'],
      pageOptions: { limit: 20 }
    });
  }

  ngOnInit() {
    this.refresh();
  }

  ngOnChanges() {
    this.refresh();
  }

  refresh() {
    // tslint:disable-next-line:max-line-length
    this.conversationService.get(`entity?entity-id=${this.conversation.entity.id}&entity-type=${this.conversation.entity.type}&entity-name=${this.conversation.entity.name}`).subscribe((c) => {
      this.conversation = c;
      this.filters.properties['conversationId'].value = this.conversation.id;

      if (this.selectedSubject) {
        this.filters.properties['subject'].value = this.selectedSubject;
      }
      this.fetch();

      this.api.afterCreate.subscribe((message: Message) => {
        if (this.conversation && message.conversation && message.conversation.id === this.conversation.id) {
          if (!this.items.find((i) => i.id === message.id)) {
            this.items.push(message);
          }
        }
      });
    });
  }
}

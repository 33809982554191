import { Injectable } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from '@angular/platform-browser';
@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {

   }

   /**
   *
   * @param iconName name of the icon to add in <mat-icon svgIcon={{iconName}}></mat-icon>
   * @param iconPath icon path
   */
  matICONGenerator(iconName: string, iconPath: string) {
    this.matIconRegistry.addSvgIcon(
      iconName,
      this.domSanitizer.bypassSecurityTrustResourceUrl(iconPath)
    );
  }


  matIconListGenerate() {
    this.matICONGenerator('bamboo-icon', 'assets/customIcons/bamboo_logo.svg');
    this.matICONGenerator('cures-icon', 'assets/customIcons/cures_logo.svg');
    this.matICONGenerator('animal-icon', 'assets/customIcons/animal.svg');
  }
}

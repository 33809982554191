import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UxService } from '~/app/core/services';
import { RoleService } from '../../core/services';
import { Course } from '../models';
import { DirectoryApi } from './directory.api';

@Injectable()
export class CourseService extends DirectoryApi<Course> {
  courses: any;
  constructor(
    http: HttpClient,
    roleService: RoleService,
    uxService: UxService) {
    super('courses', http, roleService, uxService);
  }
}

export class ModelBase {
  id: string | number;
  code: string;
  name: string;

  status: string;
  timeStamp: Date;
  isSelected = false;
  isProcessing = false;
  isDeleted = false;
  hasError = false;
  constructor(obj?: any) {
    if (!obj) { return; }

    this.id = obj.id;
    this.code = obj.code;
    this.name = obj.name;
    this.status = obj.status;
    this.timeStamp = obj.timeStamp;
  }
}

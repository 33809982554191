import { ModelBase } from '../../core/models/model-base.model';
import { Member } from './member.model';
import { ReleaseStateType } from './release-state-type.model';
import { TimeLine } from './timeline.model';
import { User } from './user.model';

export class ReleaseState extends ModelBase {
  type: ReleaseStateType;

  plan: TimeLine;
  actual: TimeLine;
  isCurrent: boolean;

  members: Member[];
}

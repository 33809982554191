import { Member, Project, Task } from '.';
import { ModelBase } from '../../core/models/model-base.model';
import { TimeLine } from './timeline.model';

export class Sprint extends ModelBase {
  description: string;

  points: number;
  burnt: number;

  plan: TimeLine;
  actual: TimeLine;

  members: Member[];
  tasks: Task[] = [];
  project: Project;
  constructor(obj?: any) {
    super(obj);
    if (!obj) { return; }

    this.description = obj.description;

    this.points = obj.points;
    this.burnt = obj.burnt;

    this.plan = new TimeLine(obj.plan);
    this.actual = new TimeLine(obj.actual);

    this.members = obj.members ? obj.members.map((i) => new Member(i)) : [];
    this.tasks = obj.tasks ? obj.tasks.map((i) => new Task(i)) : [];

    if (obj.project) { this.project = new Project(obj.project); }
  }
}

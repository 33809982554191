import { ModelBase } from '../../core/models/model-base.model';
import { ComponentType } from './component-type.model';
import { User } from './user.model';

export class Component extends ModelBase {
  value: number;

  type: ComponentType;
  user: User;

  config: any;
}

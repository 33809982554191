import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { RoleService } from '~/app/open-age/core/services';
import { Link } from '~/app/open-age/core/structures';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'app-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.css']
})
export class QuickLinksComponent implements OnChanges {

  @Input()
  code: string;

  @Input()
  items: Link[] = [];

  constructor(
    public auth: RoleService,
    public navService: NavService
  ) {
  }
  ngOnChanges(): void {
    if (this.code === 'root') {
      this.items = this.navService.getNavs().filter((i) => i.items && i.items.length);
    } else {
      const nav = this.navService.getNavs().find((n) => n.code === this.code);

      if (nav) {
        this.items = nav.items;
      }
    }
  }
}

import { Component, ErrorHandler, Input, OnDestroy, OnInit } from '@angular/core';
import { JobService } from 'src/app/open-age/send-it/services';
import { PagerModel } from '~/app/open-age/core/structures';
import { Job } from '../models';

export class JobListBaseComponent extends PagerModel<Job> implements OnInit {

  @Input()
  view: 'table' | 'list' | 'grid' = 'table';

  constructor(
    api: JobService,
    errorHandler: ErrorHandler
  ) {
    super({ api, errorHandler });
  }
  ngOnInit(): void {
    this.fetch();
  }
}

import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UxService } from 'src/app/core/services/ux.service';
import { Provider } from 'src/app/open-age/send-it/models';
import { ProviderService } from 'src/app/open-age/send-it/services';
import { DetailBase } from '~/app/open-age/core/structures';

@Component({
  selector: 'app-provider-new',
  templateUrl: './provider-new.component.html',
  styleUrls: ['./provider-new.component.css']
})
export class ProviderNewComponent extends DetailBase<Provider> implements OnInit {
  newForm: FormGroup;

  constructor(
    private uxService: UxService,
    service: ProviderService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private formBuilder: FormBuilder
  ) {
    super({
      api: service
    });

    this.newForm = new FormGroup({
      name: new FormControl('', [
        Validators.required
      ]),
      code: new FormControl('', [
        Validators.required
      ])
    });
  }

  goBack(): void {
    this.location.back();
  }

  ngOnInit() {

  }

  setAndSave = () => {
    const value = this.newForm.value;
    this.properties = new Provider({
      code: value.code,
      name: value.name
    });
    this.create();
  }
}

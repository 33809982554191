import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavService, UxService } from '~/app/core/services';
import { Link } from '~/app/open-age/core/structures';
import { OrganizationListComponent } from '~/app/open-age-ng/directory/organization-list/organization-list.component';
import { RoleService } from '~/app/open-age/core/services';

@Component({
  selector: 'app-practices',
  templateUrl: './practices.component.html',
  styleUrls: ['./practices.component.css']
})
export class PracticesComponent implements OnInit, OnDestroy {

  @ViewChild('details')
  details: OrganizationListComponent;

  code: "videos";
  isCurrent = true;
  page: Link;
  name: string;
  status: string

  constructor(
    private navService: NavService,
    private uxService: UxService,
    private route: ActivatedRoute,
    private auth: RoleService
  ) {
  }

  ngOnInit() {
    this.page = this.navService.register(`/practice/list`, this.route, (isCurrent, params) => {
      this.isCurrent = isCurrent;
      if (this.isCurrent) {
        this.setContext();
      }
    });
  }

  ngOnDestroy(): void {
    this.uxService.reset();
  }

  new() {
    this.navService.goto("/practice/list/new")
  }

  onSelect(item) {
    this.navService.goto(`/practice/list/${item.id}`)
  }

  onViewed(item) {
    this.navService.goto(`/practice/list/view/${item.id}`)
  }

  refresh() {
    this.details.fetch()
  }

  setContext() {
  }
  onReset(){
    this.name = null
  }
}

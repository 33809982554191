import { Component, Input, OnInit } from '@angular/core';
import { UxService } from 'src/app/core/services/ux.service';
import { JobDetailBaseComponent } from 'src/app/open-age/send-it/components/job-detail.base.component';
import { Template, TemplateConfig } from 'src/app/open-age/send-it/models';
import { TemplateDataSource } from 'src/app/open-age/send-it/models/template-data-source.model';
import { JobService } from '../../../open-age/send-it/services/job.service';

@Component({
  selector: 'send-it-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.css']
})
export class JobDetailsComponent extends JobDetailBaseComponent {

  @Input()
  view = 'editor';

  preview: {
    data?: any,
    subject?: string,
    body?: string
  } = {};

  constructor(
    service: JobService,
    uxService: UxService
  ) { super(service, uxService); }

  templateSelect($event: Template) {
    this.properties.template = $event;
  }
  addConfig() {
    this.properties.config = {
      modes: {
        sms: false,
        email: false,
        push: false
      }
    };
  }
  addDataSource() {
    this.properties.dataSource = new TemplateDataSource({});
  }

  onMetaSet($event) {
    this.properties.dataSource.meta = $event;
  }
}

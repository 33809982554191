import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UxService } from '~/app/core/services';
import { RoleService } from '../../core/services';
import { Component } from '../models/component.model';
import { CarApi } from './car-api.base';

@Injectable()
export class ComponentService extends CarApi<Component> {
  constructor(
    http: HttpClient,
    roleService: RoleService,
    uxService: UxService) {
    super('components', http, roleService, uxService);
  }
}

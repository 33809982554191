import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { UxService } from '~/app/core/services';
import { TaskListComponent } from '~/app/open-age-ng/gateway/task-list/task-list.component';
import { Project, Sprint } from '~/app/open-age/gateway/models';
import { ProjectType } from '~/app/open-age/gateway/models/project-type.model';
import { ProjectService, ProjectTypeService, SprintService } from '~/app/open-age/gateway/services';

@Component({
  selector: 'gateway-new-sprint-dialog',
  templateUrl: './new-sprint-dialog.component.html',
  styleUrls: ['./new-sprint-dialog.component.css']
})
export class NewSprintDialogComponent implements OnInit {

  @Input()
  project: Project;

  sprint: Sprint;

  constructor(
    public dialog: MatDialogRef<NewSprintDialogComponent>,
    public api: ProjectService,
    public sprintService: SprintService,
    public typeApi: ProjectTypeService,
    private uxService: UxService
  ) {
    this.sprint = new Sprint();
  }

  ngOnInit() {

  }
  proceed(sprint) {

    this.sprint.project = this.project;
    this.sprintService.create(this.sprint).subscribe((p) => this.dialog.close(p));
  }

  cancel() {
    this.dialog.close();
  }

}

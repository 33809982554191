import { Entity } from '../../core/models/entity.model';
import { Conversation } from './conversation.model';
import { Meta } from './meta.model';
import { User } from './user.model';

export class Message {
  id: string;
  date: Date;
  timeStamp: Date;

  to: Array<{
    user: User,
    deliveredOn?: Date,
    viewedOn?: Date,
    processedOn?: Date,
    archivedOn?: Date
  }>;
  from: User;

  modes = {
    sms: false,
    email: false,
    push: false
  };

  subject: string;
  body: string;
  data: any;

  conversation: Conversation;
  meta: Meta;

  status: string;    // for taking action on notification
  permissions: string[];  // need to post this model

  constructor(obj?: any) {

    if (!obj) {
      return;
    }

    this.id = obj.id;
    this.status = obj.status;
    this.timeStamp = obj.timeStamp;

    this.body = obj.body;
    this.meta = obj.meta;
    this.subject = obj.subject;

    this.to = [];
    if (obj.to && obj.to.length) {
      obj.to.forEach((to) => {
        this.to.push({
          user: to.user || to,
          deliveredOn: to.deliveredOn,
          viewedOn: to.viewedOn,
          archivedOn: to.archivedOn
        });
      });
    }

    this.date = obj.date;
    this.data = obj.data;
    if (obj.conversation) {
      this.conversation = new Conversation(obj.conversation);
    }

    if (obj.modes) {
      this.modes.email = !!obj.modes.email;
      this.modes.sms = !!obj.modes.sms;
      this.modes.push = !!obj.modes.push;
    }
  }
}

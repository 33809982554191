import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { DateService } from '~/app/open-age/core/services/date.service';

@Component({
  selector: 'app-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.css']
})
export class MonthPickerComponent implements OnInit, OnChanges {

  @Input()
  view = 'input';

  @Input()
  date: string | Date;

  @Input()
  isReset = false;

  @Output()
  select: EventEmitter<Date> = new EventEmitter();

  dates: Date[] = [];

  month: string;

  constructor(
    private dateService: DateService
  ) { }

  ngOnInit() {

    const date = this.dateService.parse(this.date);
    this.setDates(date);

    this.date = this.dates.find((i) => this.dateService.compare(i, date, 'date'));
  }

  ngOnChanges() {
    if (this.isReset === true) {
      this.date = null;
    }
  }

  setDates(date: Date) {
    this.dates = this.dateService.inWeek(date);

    const lastDate = this.dates[this.dates.length - 1];

    this.month = this.dateService.toString(lastDate, 'month');
  }

  click(date: Date) {
    this.date = date;
    this.select.emit(date);
  }

  showNextWeek() {
    const lastDate = this.dates[this.dates.length - 1];

    this.setDates(this.dateService.nextDay(lastDate));
  }

  showPreviousWeek() {
    this.setDates(this.dateService.previousDay(this.dates[0]));
  }

  updated($event) {

    const date = $event.currentTarget.value;

    if (date) {
      this.select.emit(moment(date).toDate());
    } else {
      this.select.emit(null);
    }
  }

}

import { ModelBase } from '../../core/models/model-base.model';

export class Batch extends ModelBase {
    name: string;
    code: string;
    year: number;
    constructor(obj?: any) {
        super(obj);
        if (!obj) {
            return;
        }
        this.code = obj.code;
        this.name = obj.name;
        this.year = obj.year;
    }
}

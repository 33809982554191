import { Component, Input, OnChanges, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Entity } from '~/app/open-age/core/models/entity.model';
import { RoleService } from '~/app/open-age/core/services';
import { Organization } from '~/app/open-age/directory/models';
import { Folder } from '~/app/open-age/drive/models/folder.model';
import { FolderService } from '~/app/open-age/drive/services';
import { FileListComponent } from '../file-list/file-list.component';
import { Doc } from '~/app/open-age/drive/models';

@Component({
  selector: 'app-folder-detail',
  templateUrl: './folder-detail.component.html',
  styleUrls: ['./folder-detail.component.css']
})
export class FolderDetailComponent implements OnInit, OnChanges {

  @Input()
  view: 'table' | 'list' | 'grid' = 'grid';

  @ViewChild('list')
  list: FileListComponent;

  @Input()
  viewOnly: boolean;

  @Input()
  code: string;

  @Input()
  folder: Folder;

  @Input()
  entity: Entity;

  @Input()
  fileName: string;

  @Output()
  selected: EventEmitter<Doc> = new EventEmitter();

  org: Organization;

  isProcessing: boolean;

  constructor(
    public dialog: MatDialog,
    private service: FolderService,
    private roleService: RoleService
  ) { }
  ngOnChanges(): void {
    // this.fetch();
  }

  ngOnInit() {
    this.org = this.roleService.currentOrganization();
    if (!this.folder) {
      this.folder = new Folder({
        name: this.code || "list",
        owner: 'system',
        entity: {
          id: this.org.code,
          type: this.org.name
        }
      });
    }
  }

  fetch() {
    this.list.fetch()
  }

  upload() {
    this.list.openDialog()
  }

  // onSelected($event) {
  //   this.selected.emit($event)
  // }
}

import { ModelBase } from '../../core/models/model-base.model';

export class Category extends ModelBase {
  color: string;

  constructor(obj?: any) {
    super(obj);
    if (!obj) { return; }

    this.color = obj.color;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UxService } from '~/app/core/services';
import { GenericApi, RoleService } from '../../core/services';
import { AvailableDate, AvailableTime } from '../models/appointment-type.model';
import { WelcomeApi } from './welcome-api.base';

@Injectable()
export class AvailabilityService extends WelcomeApi<any> {

  dates: GenericApi<AvailableDate>;
  slots: GenericApi<AvailableTime>;

  constructor(
    http: HttpClient,
    roleService: RoleService,
    uxService: UxService) {
    super('availabilities', http, roleService, uxService);
    this.dates = new WelcomeApi<AvailableDate>(`availabilities/getByMonth`, http, roleService, uxService);
    this.slots = new WelcomeApi<AvailableTime>(`availabilities/getByDate`, http, roleService, uxService);
  }
}

import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Task } from '~/app/open-age/gateway/models';
import { State } from '~/app/open-age/gateway/models/state.model';
import { Workflow } from '~/app/open-age/gateway/models/workflow.model';

@Component({
  selector: 'gateway-task-process',
  templateUrl: './task-process.component.html',
  styleUrls: ['./task-process.component.css']
})
export class TaskProcessComponent implements OnInit, OnChanges {

  @Input()
  item: Task;

  @Input()
  workflow: Workflow;

  @Input()
  current: State;

  constructor() { }

  ngOnChanges(): void {
    if (this.item) {
      this.workflow = this.item.workflow;
      this.current = this.item.currentStatus;
    }

    this.setProcess();
  }

  ngOnInit() {
  }

  setProcess() {
    let match = false;
    this.workflow.states.forEach((s) => s.status = 'pending');
    if (!this.current) {
      return;
    }
    this.workflow.states.forEach((state) => {
      if (!match) {
        state.isCancelled = true;
        state.status = 'done';
        if (state.code === this.current.code) {
          state.isCancelled = false;
          state.status = state.isFinal ? 'done' : 'wip';
          match = true;
        }
      }
    });
  }
}

import { ModelBase } from '../../core/models/model-base.model';

export class Calendar extends ModelBase {

    acuityId: String;
    email: String;
    description: String;

    constructor(obj?: any) {
        super();
        if (!obj) {
            return;
        }
        this.id = obj.id;
        this.acuityId = obj.acuityId;
        this.description = obj.description;
        this.name = obj.name;
        this.email = obj.email;
    }

}

export class Category extends ModelBase {

    email: Boolean;
    description: String;
    isClinical: Boolean;
    isSequencial: Boolean;
    isOneAllowed: Boolean;

    constructor(obj?: any) {
        super();
        if (!obj) {
            return;
        }
        this.id = obj.id;
        this.description = obj.description;
        this.name = obj.name;
        this.isClinical = obj.isClinical;
        this.isOneAllowed = obj.isOneAllowed;
        this.isSequencial = obj.isSequencial;
    }

}

import { Entity } from '../../core/models';
import { ModelBase } from '../../core/models/model-base.model';
import { Employee } from '../../directory/models';
import { Doc } from '../../drive/models/doc.model';
import { Category } from './category.model';
import { Member } from './member.model';
import { Project } from './project.model';
import { Release } from './release.model';
import { Sprint } from './sprint.model';
import { State } from './state.model';
import { TimeLine } from './timeline.model';
import { User } from './user.model';
import { Workflow } from './workflow.model';

export class Task extends ModelBase {
  subject: string;
  description: string;

  currentStatus: State;

  entity: Entity;

  effort: number;
  points: number;
  burnt: number;

  plan: TimeLine;
  actual: TimeLine;

  type: string;
  priority: string;
  size: number;

  user: User;
  members: Member[];

  parent: Task;
  children: Task[];

  attachments: Doc[];

  category: Category;
  sprint: Sprint;
  release: Release;
  project: Project;
  workflow: Workflow;
  assignedTo: User;
  assignee: Employee;

  constructor(obj?: any) {
    super(obj);
    if (!obj) { return; }

    this.subject = obj.subject;
    this.description = obj.description;
    if (obj.entity) { this.entity = new Entity(obj.entity); }

    this.effort = obj.effort;
    this.points = obj.points;
    this.burnt = obj.burnt;
    this.size = obj.size;

    this.plan = new TimeLine(obj.plan);
    this.actual = new TimeLine(obj.actual);

    this.type = obj.type;
    this.priority = obj.priority;

    if (obj.user) { this.user = new User(obj.user); }
    this.members = obj.members ? obj.members.map((i) => new Member(i)) : [];

    if (obj.parent) { this.parent = new Task(obj.parent); }
    this.children = obj.children ? obj.children.map((i) => new Task(i)) : [];

    this.attachments = obj.attachments ? obj.attachments.map((i) => new Doc(i)) : [];

    this.workflow = new Workflow(obj.workflow);

    this.currentStatus = obj.status ?
      this.workflow.states.find((s) => s.code === obj.status.code) :
      this.workflow.states.find((s) => s.isFirst);

    if (obj.category) { this.category = new Category(obj.category); }
    if (obj.sprint) { this.sprint = new Sprint(obj.sprint); }
    if (obj.release) { this.release = new Release(obj.release); }
    if (obj.project) { this.project = new Project(obj.project); }

    if (obj.assignedTo) { this.assignedTo = new User(obj.assignedTo); }
    this.assignee = new Employee(obj.assignee);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UxService } from '~/app/core/services';
import { RoleService } from '../../core/services';
import { Biometric } from '../models/biometric.model';
import { AmsApi } from './ams-api.base';

@Injectable()
export class BiometricService extends AmsApi<Biometric> {
  constructor(
    http: HttpClient,
    roleService: RoleService, uxService: UxService) {
    super('biometrics', http, roleService, uxService);
  }
}

import { Component } from '@angular/core';
import { MessageDetailBaseComponent } from 'src/app/open-age/send-it/components/message-detail.base.component';
import { UxService } from '../../../core/services/ux.service';
import { MessageService } from '../../../open-age/send-it/services/message.service';

@Component({
  selector: 'send-it-message-detail',
  templateUrl: './message-detail.component.html',
  styleUrls: ['./message-detail.component.css']
})
export class MessageDetailComponent extends MessageDetailBaseComponent {

  constructor(
    api: MessageService,
    uxService: UxService
  ) {
    super(api, uxService);
  }
}

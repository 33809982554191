import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule, MatCardModule, MatCheckboxModule, MatIconModule, MatTableModule } from '@angular/material';
import { SharedModule } from '~/app/shared/shared.module';
import { DriveModule } from '../../open-age/drive/drive.module';
import { DpComponentComponent } from './dp-component/dp-component.component';
import { FileListUploaderComponent } from './file-list-uploader/file-list-uploader.component';
import { FileListComponent } from './file-list/file-list.component';
import { FileUploaderDialogComponent } from './file-uploader-dialog/file-uploader-dialog.component';
import { FileUploaderZoneComponent } from './file-uploader-zone/file-uploader-zone.component';
import { FolderDetailComponent } from './folder-detail/folder-detail.component';
import { FolderListComponent } from './folder-list/folder-list.component';
import { ImageEditorComponent } from './image-editor/image-editor.component';
import { NewFolderComponent } from './new-folder/new-folder.component';
import { FileDetailComponent } from './file-detail/file-detail.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

const components = [
  FileListComponent,
  FileUploaderZoneComponent,
  ImageEditorComponent,
  FileListUploaderComponent,
  FolderListComponent,
  FolderDetailComponent,
  FileUploaderDialogComponent,
  NewFolderComponent,
  DpComponentComponent,
  FileDetailComponent
];
const thirdPartyModules = [
  MatButtonModule,
  MatIconModule,
  MatCheckboxModule,
  MatCardModule,
  MatTableModule,
  MatSlideToggleModule
];
const services = [];
const guards = [];
const pipes = [];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DriveModule,
    SharedModule,
    ...thirdPartyModules,
  ],
  declarations: [...components, ...pipes],
  exports: [...components, ...pipes],
  providers: [...services, ...guards],
  entryComponents: [FileUploaderZoneComponent, FileUploaderDialogComponent]
})
export class OaDriveModule { }

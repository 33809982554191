import { ErrorHandler, Input, OnChanges, OnInit } from '@angular/core';
import { Doc } from '../models';
import { DocsService } from '../services/docs.service';
import { DetailBase } from '../../core/structures';

export class FileDetailsBaseComponent extends DetailBase<Doc> implements OnInit, OnChanges {

  @Input()
  id: string;

  constructor(
    private api: DocsService,
    private errorHandler: ErrorHandler,
  ) {
    super({ api, errorHandler });
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.get(this.id)
  }

  ngOnDestroy(): void {
  }
}

import { Component } from '@angular/core';
import { UxService } from 'src/app/core/services/ux.service';
import { ChannelListBaseComponent } from 'src/app/open-age/send-it/components/channel-list.base.component';
import { ChannelService } from 'src/app/open-age/send-it/services';
import { Channel } from '../../../open-age/send-it/models/channel.model';
import { ProviderService } from '../../../open-age/send-it/services/provider.service';

@Component({
  selector: 'send-it-channel-list',
  templateUrl: './channel-list.component.html',
  styleUrls: ['./channel-list.component.css']
})
export class ChannelListComponent extends ChannelListBaseComponent {

  constructor(
    providerService: ProviderService,
    service: ChannelService,
    uxService: UxService
  ) {
    super(providerService, service, uxService);
  }

  setConfig($event: any, item: Channel) {
    item.config = $event;
  }

}

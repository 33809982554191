import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Task } from '~/app/open-age/gateway/models';
import { State } from '~/app/open-age/gateway/models/state.model';
import { TimeLine } from '~/app/open-age/gateway/models/timeline.model';
import { TaskService } from '~/app/open-age/gateway/services';

@Component({
  selector: 'gateway-task-status-bar',
  templateUrl: './task-status-bar.component.html',
  styleUrls: ['./task-status-bar.component.css']
})
export class TaskStatusBarComponent implements OnInit {

  @Input()
  task: Task;

  @Input()
  view: {
    slides?: boolean,
    children?: boolean,
    members?: boolean
  } = {
      slides: true,
      children: false,
      members: false
    };

  effortUnits = 'Point(s)';

  @Output()
  change: EventEmitter<{
    slides?: boolean,
    children?: boolean,
    members?: boolean
  }> = new EventEmitter();

  constructor(
    private service: TaskService
  ) {

    service.afterUpdate.subscribe((t) => {
      if (t.id !== this.task.id) {
        return;
      }

      this.task = t;
      this.calculate();
    });
  }

  ngOnInit() {
    this.calculate();
  }

  calculate() {
    this.task.burnt = this.task.burnt || 0;
    this.task.size = this.task.size || 0;
  }

  getSizeStyle() {
    const percentage = (100 * (this.task.burnt) / (this.task.size || 1));

    return {
      background: percentage ? '' : `linear-gradient(90deg, var(--default) ${percentage}%, transparent ${100 - percentage}%)`
    };
  }

  updateStatus(item: State) {
    this.service.updateStatus(this.task, item);
  }

  toggleShowSlides() {
    this.view.slides = !this.view.slides;
    this.change.emit(this.view);
  }

  toggleShowTasks() {
    this.view.children = !this.view.children;
    this.change.emit(this.view);
  }

  setPlan($event: TimeLine) {
    this.service.updatePlan(this.task, $event);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Release, Role } from 'src/app/open-age/gateway/models';
import { ReleaseState } from 'src/app/open-age/gateway/models/release-state.model';
import { WorkflowsService } from '~/app/open-age/gateway/services/workflows.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'gateway-workflow-progress',
  templateUrl: './workflow-progress.component.html',
  styleUrls: ['./workflow-progress.component.css']
})
export class WorkflowProgressComponent implements OnInit {

  @Input()
  release: Release;

  states: ReleaseState[] = [];

  selectedState: ReleaseState;

  role: Role;

  constructor(
    private workflowStateService: WorkflowsService
  ) {

    this.role = new Role({ code: 'assigned', name: 'Assignees' });
  }

  ngOnInit() {
    this.states = this.release.states;
    this.selectedState = this.release.states.find((i) => i.isCurrent);
    if (!this.selectedState) {
      this.selectedState = this.release.states[0];
    }
    this.selectedState.isSelected = true;
  }

  start($event: Date, item: ReleaseState) {
    this.workflowStateService.update(item.id, {
      actual: {
        start: new Date()
      }
    }).subscribe((i) => {
      // item.actual.start = i.actual.start;
    });

  }
  finish($event: Date, item: ReleaseState) {
    this.workflowStateService.update(item.id, {
      actual: {
        finish: new Date()
      }
    }).subscribe((i) => {
      // item.actual.finish = i.actual.finish;
    });
  }

  addNext(state: ReleaseState) {

  }

  select(item: ReleaseState) {
    this.states.forEach((i) => i.isSelected = false);
    item.isSelected = true;
    this.selectedState = item;
  }

}

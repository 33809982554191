import { UxService } from '../../../core/services/ux.service';
import { TelepainApiBase } from './telepain-api.base';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RoleService } from '../../core/services/role.service';
import { SurveyCategory } from '../models/survey-category';

@Injectable({
  providedIn: 'root'
})
export class SurveyCategoryService extends TelepainApiBase<SurveyCategory>{

  constructor(
    http: HttpClient,
    roleService: RoleService,
    uxService: UxService) {
    super('survey-categories', http, roleService, uxService);
  }
}

import { ModelBase } from '../../core/models';
import { Member } from './member.model';
import { Project } from './project.model';
import { ReleaseState } from './release-state.model';
import { Task } from './task.model';
import { TimeLine } from './timeline.model';

export class Release extends ModelBase {
  description: string;
  type: string;

  points: number;

  plan: TimeLine;
  actual: TimeLine;

  members: Member[];
  tasks: Task[] = [];
  project: Project;

  states: ReleaseState[];
  isClosed: boolean;

  constructor(obj?: any) {
    super(obj);
    if (!obj) { return; }

    this.description = obj.description;
    this.type = obj.type;

    this.points = obj.points;
    this.isClosed = obj.isClosed;

    this.plan = new TimeLine(obj.plan);
    this.actual = new TimeLine(obj.actual);

    this.members = obj.members ? obj.members.map((i) => new Member(i)) : [];
    this.tasks = obj.tasks ? obj.tasks.map((i) => new Task(i)) : [];

    if (obj.project) { this.project = new Project(obj.project); }

  }
}

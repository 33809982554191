import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { UxService } from 'src/app/core/services/ux.service';
import { Organization } from '~/app/open-age/welcome/models/organization.model';
import { OrganizationService } from '~/app/open-age/welcome/services';
import { SelectProviderDialogComponent } from '../../dialog/select-provider/select-provider.component';
import { Calendar, Category } from '~/app/open-age/welcome/models/calendar.model';
import { CalendarService } from '~/app/open-age/welcome/services/calendar.service';
import { User } from '~/app/open-age/welcome/models/user.model';

@Component({
  selector: 'welcome-practice-practitioners',
  templateUrl: './welcome-practice-practitioners.component.html',
  styleUrls: ['./welcome-practice-practitioners.component.css'],
})
export class WelcomePracticePractitioners implements OnInit {

  @Input()
  code: string

  isProcessing = false
  organization: Organization

  calendars: {}

  constructor(
    public dialog: MatDialog,
    private organizationService: OrganizationService,
    private calendarApi: CalendarService,
    private uxService: UxService
  ) {
  }

  ngOnInit() {
    this.isProcessing = true
    this.organizationService.get(this.code).subscribe((item) => {
      this.organization = new Organization(item)
      this.isProcessing = false
    }, err => {
      this.isProcessing = false
    })
  }

  addProvider() {
    const dialogRef = this.dialog.open(SelectProviderDialogComponent, {
      width: '450px',
    });
    if (this.organization.users && this.organization.users.length) {
      dialogRef.componentInstance.existingUsers = this.organization.users.map((item) => item.user)
    } else {
      dialogRef.componentInstance.existingUsers = []
    }
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.isProcessing = true
        this.organizationService.update(`addUser/${this.organization.id}`, result).subscribe((item) => {
          this.organization = new Organization(item)
          this.isProcessing = false
        }, err => {
          this.isProcessing = false
        })
      }
    })
  }

  refresh() {
    this.isProcessing = true
    this.organizationService.get(this.code).subscribe((item) => {
      this.organization = new Organization(item)
      this.isProcessing = false
    }, err => {
      this.isProcessing = false
    })
  }

  update() {
    this.organization.users.forEach((user, index) => {
      for (const key in user.grouped) {
        for (const item of user.grouped[key].calendars) {
          let index = this.findCalendarIndex(item.calendar, user.calendars)
          if (index || index == 0) {
            user.calendars[index].isActive = item.isActive
          }
        }
      }
      this.organization.users[index] = user
    });
    this.isProcessing = true
    this.organizationService.update(this.organization.id, this.organization).subscribe(item => {
      this.organization = new Organization(item)
      this.isProcessing = false
    }, err => {
      this.isProcessing = false
    })
  }

  findCalendarIndex(calendar: Calendar, calendars: any) {
    let indexFound
    calendars.forEach(function (item, index) {
      if (item.calendar.id == calendar.id) {
        indexFound = index
      }
    })
    return indexFound
  }

  findUserIndex(user: User, users: any) {
    let indexFound
    users.forEach(function (item, index) {
      if (item.user.id == user.id) {
        indexFound = index
      }
    })
    return indexFound
  }

  objectKeys(obj) {
    return Object.keys(obj);
  }

  onCheckBoxClick($event, calendar) {
    if (calendar.wasActive) {
      $event.preventDefault()
    }
  }

}

import { Component, OnInit, Output, EventEmitter, Input, ErrorHandler, NgZone } from '@angular/core';
import { Role, User } from '~/app/open-age/directory/models';
import { RoleService, IInputValidator } from '~/app/open-age/core/services';
import { accounts } from 'google-one-tap';
declare var google: any;

@Component({
  selector: 'auth-google-login-button',
  templateUrl: './google-login-button.component.html',
  styleUrls: ['./google-login-button.component.css'],
})
export class GoogleLoginButtonComponent implements OnInit {

  @Output()
  success: EventEmitter<Role> = new EventEmitter();

  @Output()
  failure: EventEmitter<Error> = new EventEmitter();

  @Input()
  isProcessing = false;

  private scope = [
    // 'profile',
    'email',
    // 'https://www.googleapis.com/auth/plus.me',
    // 'https://www.googleapis.com/auth/contacts.readonly',
    // 'https://www.googleapis.com/auth/admin.directory.user.readonly'
  ].join(' ');

  public auth2: any;
  code: string;
  error: string;

  constructor(
    private auth: RoleService,
    private errorHandler: ErrorHandler,
    private ngZone: NgZone
  ) { }

  ngOnInit() {
    const gAccounts: accounts = google.accounts;

    gAccounts.id.initialize({
      client_id: this.auth.currentTenant().meta.google.webClientId,
      ux_mode: 'popup',
      cancel_on_tap_outside: true,
      callback: ({ credential }) => {
        this.ngZone.run(() => {
          this._loginWithGoogle(credential);
        });
      },
    });

    gAccounts.id.renderButton(document.getElementById('gbtn') as HTMLElement, {
      size: 'large',
      width: 300,
    });
  }

  private _loginWithGoogle(token: string) {
    this.auth.authSuccess(token, "google").subscribe((role: Role) => {
      this.isProcessing = false;
      this.success.next(role);
    }, (err) => {
      this.isProcessing = false;
      const error = new Error("User does not exist");
      error.name = err.message || "User does not exist";
      error.message = err.message || "User does not exist";

      if (error.name === 'PASSWORD_INVALID') {
        error.message = 'wrong password';
      }

      this.error = error.message;

      this.failure.next(error);
      this.errorHandler.handleError(error.message || "User does not exist");
    });
  }
}


import { ErrorHandler, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DetailBase } from '~/app/open-age/core/structures';
import { Role } from '../../directory/models';
import { Component } from '../models/component.model';
import { User } from '../models/user.model';
import { ComponentService } from '../services';

export class ComponentDetailBaseComponent extends DetailBase<Component> implements OnInit, OnChanges {
  @Input()
  code: string;

  @Input()
  userCode: string;

  @Input()
  readonly = false;

  constructor(
    api: ComponentService,
    errorHandler: ErrorHandler
  ) {
    super({ api, errorHandler });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.code) {
      this.get(this.code).subscribe((p) => this.init());
    } else {
      this.set(this.newItem());
    }
  }

  ngOnInit() {
    if (this.code) {
      this.get(this.code).subscribe((p) => this.init());
    } else {
      this.set(this.newItem());
    }
  }

  init() { }

  newItem() {
    const item = new Component();
    item.user = new User();
    item.user.code = this.userCode;
    return item;
  }
}

import { ErrorHandler, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { PagerBaseComponent } from '~/app/open-age/core/structures';
import { Biometric } from '../models/biometric.model';
import { Device } from '../models/device.model';
import { User } from '../models/user.model';
import { BiometricService } from '../services/biometric.service';
import { UserService } from '../services/user.service';

export class BiometricListBaseComponent extends PagerBaseComponent<Biometric> implements OnInit, OnChanges, OnDestroy {

  @Input()
  readonly = false;

  @Input()
  device: Device;

  @Input()
  category: string;

  @Input()
  employeeId: string;

  @Input()
  sort = 'code';

  @Input()
  codeFilter = '';

  @Input()
  statusFilter = '';

  @Input()
  tempFilter = false;

  @Input()
  userCodeFilter = '';

  constructor(
    private service: BiometricService,
    errorHandler: ErrorHandler
  ) {
    super({
      api: service,
      errorHandler,
      pageOptions: { limit: 10 },
      filters: ['userId', 'deviceId', 'code', 'status', 'userStatus', 'userCode'],
    });
  }

  ngOnInit() {
    this.search();
  }

  ngOnChanges(): void {

    this.search();
  }

  search() {

    this.filters.properties['deviceId'].value = this.device ? this.device.id : '';

    this.filters.properties['userId'].value = this.employeeId;

    this.filters.properties['code'].value = this.codeFilter;

    this.filters.properties['status'].value = this.statusFilter;

    this.filters.properties['userCode'].value = this.userCodeFilter;

    if (this.tempFilter) {
      this.filters.properties['userStatus'].value = 'temp';
    } else {
      this.filters.properties['userStatus'].value = '';
    }

    this.currentPageNo = 1;

    this.fetch();
  }

  mark(biometric: Biometric, status: string) {
    const oldStatus = biometric.status;
    biometric.status = status;
    biometric.isProcessing = true;
    const promise = biometric.id ?
      this.service.update(biometric.id, biometric) :
      this.service.create(biometric);
    promise.subscribe((updated) => {
      biometric.isProcessing = false;
      biometric.id = updated.id;
    }, (err) => {
      biometric.isProcessing = false;
      biometric.status = oldStatus;
    });

  }

  ngOnDestroy(): void {
  }

}

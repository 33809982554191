import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IUser, Pic } from '~/app/open-age/core/models';

@Component({
  selector: 'oa-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css']
})
export class AvatarComponent implements OnInit {

  @Input()
  pic: Pic;

  @Input()
  text: string;

  @Input()
  user: IUser;

  @Input()
  view: 'text' | 'avatar' | 'pic' = 'avatar';

  @Input()
  shape: 'square' | 'round' = 'round';

  @Input()
  size = 40;

  @Input()
  border = 'var(--default)';

  @Input()
  color = 'var(--default)';

  @Output()
  click: EventEmitter<any> = new EventEmitter();

  style: any;

  constructor() { }

  ngOnInit() {

    if (this.user && this.user.profile && this.user.profile.pic && this.user.profile.pic.url) {
      this.pic = this.user.profile.pic;
    }

    if (this.pic) {
      this.view = 'pic';
    } else if (this.user) {
      if (this.user.profile && this.user.profile.firstName) {
        this.text = this.user.profile.firstName;
        if (this.user.profile.lastName) {
          this.text = `${this.text} ${this.user.profile.lastName}`;
        }
      }

      if (!this.text && this.user.code) {
        this.text = this.user.code;
      }

      if (!this.text && this.user.email) {
        this.text = this.user.email;
      }

      if (!this.text && this.user.phone) {
        this.text = this.user.phone;
      }
    }

    this.style = {
      backgroundColor: '#ffffff',
    };

    if (this.border && this.border !== 'none') {
      this.style['border'] = `1px solid ${this.border}`;
    }

    if (this.color) {
      this.style['color'] = this.color;
    }

    switch (this.view) {
      case 'pic':
        this.style['background-image'] = `url("${this.pic.url}")`;
        this.style['background-repeat'] = 'no-repeat';

        this.style['background-size'] = 'cover';
        this.style['height'] = `${this.size}px`;
        this.style['width'] = `${this.size}px`;
        break;
    }

    switch (this.shape) {
      case 'round':
        this.style['borderRadius'] = `50%`;
        break;
    }

    // switch (this.size) {
    //   default:
    //     this.picSize = 40;
    //     break;
    // }
  }

  onClick() {
    this.click.emit();
  }

}

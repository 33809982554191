import { ModelBase } from '../../core/models/model-base.model';
import { Project } from './project.model';
import { Release } from './release.model';
import { Role } from './role.model';
import { Sprint } from './sprint.model';
import { Task } from './task.model';
import { User } from './user.model';

export class Member extends ModelBase {
  user: User;
  size: number;
  burnt: number;

  role: Role;

  task: Task;
  sprint: Sprint;
  release: Release;
  project: Project;

  constructor(obj?: any) {
    super(obj);
    if (!obj) { return; }

    if (obj.user) { this.user = new User(obj.user); }

    this.size = obj.size;
    this.burnt = obj.burnt;

    if (obj.role) { this.role = new Role(obj.role); }
    if (obj.task) { this.task = new Task(obj.task); }
    if (obj.sprint) { this.sprint = new Sprint(obj.sprint); }
    if (obj.release) { this.release = new Release(obj.release); }
    if (obj.project) { this.project = new Project(obj.project); }
  }
}

import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {

  encryptSecretKey = "opos"

  constructor() { }

  getItem(key: string): any {
    return this.decryptData(window.sessionStorage.getItem(key));
  }

  setItem(key: string, value: string): void {
    window.sessionStorage.setItem(key, this.encryptData(value));
  }

  clear(): void {
    return window.sessionStorage.clear();
  }

  get(id: string): any {
    const item = window.sessionStorage.getItem(id);
    try {
      return item ? JSON.parse(this.decryptData(item)) : null;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  update(id: string, value: any): any {
    if (!value) {
      window.sessionStorage.removeItem(id);
    } else {
      window.sessionStorage.setItem(id, this.encryptData(JSON.stringify(value)));
    }
    return value;
  }

  remove(id: string) {
    window.sessionStorage.removeItem(id);
  }
  encryptData(data) {

    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptSecretKey).toString();
    } catch (e) {
      console.log(e);
    }
  }

  decryptData(data) {

    try {
      const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatOptionModule,
  MatRadioModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatTableModule
} from '@angular/material';

import { SendItModule } from '../../open-age/send-it/send-it.module';
import { SharedModule } from '../../shared/shared.module';
import { OaCommonModule } from '../common/oa-common.module';
import { ChannelListComponent } from './channel-list/channel-list.component';
import { ConfigEditorComponent } from './config-editor/config-editor.component';
import { ConfigFormComponent } from './config-form/config-form.component';
import { ConversationDetailComponent } from './conversation-detail/conversation-detail.component';
import { InboxComponent } from './inbox/inbox.component';
import { JobDetailsComponent } from './job-details/job-details.component';
import { JobListComponent } from './job-list/job-list.component';
import { MessageComposerComponent } from './message-composer/message-composer.component';
import { MessageDetailComponent } from './message-detail/message-detail.component';
import { NewMessageComponent } from './new-message/new-message.component';
import { NewNotificationComponent } from './new-notification/new-notification.component';
import { NoteEditorComponent } from './note-editor/note-editor.component';
import { NoteListComponent } from './note-list/note-list.component';
import { OutboxComponent } from './outbox/outbox.component';
import { ProviderDetailComponent } from './provider-detail/provider-detail.component';
import { ProviderListComponent } from './provider-list/provider-list.component';
import { ProviderNewComponent } from './provider-new/provider-new.component';
import { TemplateDetailsComponent } from './template-details/template-details.component';
import { TemplateListComponent } from './template-list/template-list.component';
import { TemplatePickerComponent } from './template-picker/template-picker.component';

const components = [
  InboxComponent,
  OutboxComponent,
  ConversationDetailComponent,

  MessageDetailComponent,
  MessageComposerComponent,

  NewNotificationComponent,
  NewMessageComponent,

  // TODO: admin components - move to separate module
  TemplateListComponent,
  TemplateDetailsComponent,
  TemplatePickerComponent,

  JobListComponent,
  JobDetailsComponent,

  ChannelListComponent,
  ProviderListComponent,

  ConfigFormComponent,

  ConfigEditorComponent,

  ProviderDetailComponent,
  ProviderNewComponent,

  NoteListComponent,
  NoteEditorComponent
];
const thirdPartyModules = [
  MatButtonModule,
  MatFormFieldModule,
  MatIconModule,
  MatMenuModule,
  MatInputModule,
  MatListModule,
  MatExpansionModule,
  MatCheckboxModule,
  MatOptionModule,
  MatGridListModule,
  MatSelectModule,
  MatDialogModule,
  MatTableModule,
  MatAutocompleteModule,
  MatRadioModule,
  MatSlideToggleModule,
  MatChipsModule
];
const services = [];
const guards = [];
const pipes = [];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SendItModule,
    SharedModule,
    OaCommonModule,
    ...thirdPartyModules,
  ],
  declarations: [...components, ...pipes],
  exports: [...components, ...pipes],
  providers: [...services, ...guards],
})
export class OaSendItModule { }

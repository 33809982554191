import { Component, OnInit } from '@angular/core';
import { UxService } from 'src/app/core/services/ux.service';
import { ReportDataBaseComponent } from 'src/app/open-age/insight/components/report-data.base.component';
import { ReportDataService } from 'src/app/open-age/insight/services/report-data.service';

@Component({
  selector: 'insight-report-data',
  templateUrl: './report-data.component.html',
  styleUrls: ['./report-data.component.css']
})
export class ReportDataComponent extends ReportDataBaseComponent {
  constructor(
    api: ReportDataService,
    errorHandler: UxService
  ) {
    super(api, errorHandler);
   }
}

import { ErrorHandler, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { PagerBaseComponent } from '~/app/open-age/core/structures';
import { Organization } from '../models';
import { OrganizationService } from '../services';
import { MatSlideToggleChange } from '@angular/material';
import { PageOptions } from '../../core/models';
import { UxService } from '~/app/core/services';

export class OrganizationListBaseComponent extends PagerBaseComponent<Organization> implements OnInit, OnChanges, OnDestroy {

  @Input()
  readonly = false;

  @Input()
  code: string;

  @Input()
  name: string;

  @Input()
  status: string = "active";

  @Input()
  type: string;

  constructor(
    private api: OrganizationService,
    private errorHandler: UxService
  ) {
    super({
      api,
      errorHandler,
      filters: [
        'name',
        'code',
        'type',
        'status'
      ],
      pageOptions: {
        limit: errorHandler.getItemsLength(),
      },
    });
    this.afterFetching = () => {
      this.convertNumbers()
    }
  }

  ngOnInit() {
    this.fetch().subscribe();
  }

  ngOnChanges() {
    this.filters.reset(false);
    if (this.code) {
      this.filters.set('code', this.code);
    }
    if (this.status) {
      this.filters.set('status', this.status);
    }
    if (this.name) {
      this.filters.set('name', this.name);
      this.filters.set('status', "all");
    }
    if (this.type) {
      this.filters.set('type', this.type);
    }
    this.fetch(new PageOptions({ limit: this.errorHandler.getItemsLength() })).subscribe();
  }

  refresh() {
    this.filters.reset(false);
    if (this.code) {
      this.filters.set('code', this.code);
    }
    if (this.status) {
      this.filters.set('status', this.status);
    }
    if (this.name) {
      this.filters.set('name', this.name);
      this.filters.set('status', "all");
    }
    if (this.type) {
      this.filters.set('type', this.type);
    }
    this.fetch(new PageOptions({ limit: this.errorHandler.getItemsLength() })).subscribe();
  }

  ngOnDestroy(): void {
  }

  public toggle(item: Organization, event: MatSlideToggleChange) {
    if (item.status == 'active') {
      item.status = 'inactive'
    } else {
      item.status = 'active'
    }
    this.api.update(item.id, item).subscribe(() => {
      this.fetch(new PageOptions({ limit: this.errorHandler.getItemsLength() })).subscribe();
    })
  }

  convertNumbers() {
    let i = 0
    for (const item of this.items) {
      if (item.phone) {
        this.phoneNumberFormat(item.phone, i)
      }
      i++
    }
  }

  phoneNumberFormat(mobile, index) {
    let phoneNumber = mobile
    let phoneNumberArray
    phoneNumber = phoneNumber.replace(/[^0-9]*/g, '')
    if (phoneNumber) {
      phoneNumberArray = phoneNumber.split('')
      phoneNumber = ''
      phoneNumberArray.forEach((item, i) => {
        if (i < 2) { phoneNumber = phoneNumber + item }
        if (i == 2) { phoneNumber = phoneNumber + item + '-' }
        if ((i < 5) && (i > 2)) { phoneNumber = phoneNumber + item }
        if (i == 5) { phoneNumber = phoneNumber + item + '-' }
        if (i > 5 && i < 11) { phoneNumber = phoneNumber + item }
      });
    }
    return this.items[index].phone = phoneNumber
  }

}

import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CalendarEvent } from '~/app/open-age/insight/models/calendar-event.model';

@Component({
  selector: 'insight-calender-day-detail',
  templateUrl: './calender-day-detail.component.html',
  styleUrls: ['./calender-day-detail.component.css']
})
export class CalenderDayDetailComponent implements OnInit {

  @Input()
  event: CalendarEvent;

  constructor(
    public dialogRef: MatDialogRef<CalenderDayDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(data);
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    console.log(event);
  }
}

import { ModelBase } from '../../core/models/model-base.model';
import { Patient } from './patient';

export class HealthData extends ModelBase {
    paramKey: string;
    recordedDate: Date;
    patient: Patient;
    paramValue: {
        unit: string,
        value: string
    };

    constructor(obj?: any) {
        super();
        if (!obj) {
            return;
        }

        this.id = obj.id;
        this.recordedDate = new Date(obj.recordedDate)
        this.paramKey = obj.paramKey;

        if (obj.paramValue) {
            this.paramValue = {
                unit: obj.paramValue.unit,
                value: obj.paramValue.value
            };
        }

        this.patient = new Patient(obj.patient)
    }
}

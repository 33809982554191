import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UxService } from '~/app/core/services';
import { GenericApi, RoleService } from '../../core/services';
import { Appointment } from '../models/appointment.model';
import { WelcomeApi } from './welcome-api.base';

@Injectable()
export class AppointmentsService extends WelcomeApi<Appointment> {
  
  patientAppointments: GenericApi<Appointment>;

  constructor(
    http: HttpClient,
    roleService: RoleService,
    uxService: UxService) {
    super('appointments', http, roleService, uxService);
    this.patientAppointments = new WelcomeApi<Appointment>(`appointments/searchWithPatientAttandance`, http, roleService, uxService);
  }
}

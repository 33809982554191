import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material';
import { debounceTime, switchMap } from 'rxjs/operators';
import { ComponentTypeService } from '~/app/open-age/car/services';
import { ComponentType } from '../../../open-age/car/models/component-type.model';

@Component({
  selector: 'car-component-type-picker',
  templateUrl: './component-type-picker.component.html',
  styleUrls: ['./component-type-picker.component.css']
})
export class ComponentTypePickerComponent implements OnInit {

  @Input()
  model: ComponentType;

  @Input()
  placeholder: string;

  @Output()
  changed: EventEmitter<ComponentType> = new EventEmitter();

  @ViewChild('userInput')
  userInput: ElementRef<HTMLInputElement>;

  @ViewChild('employeePicker')
  matAutocomplete: MatAutocomplete;

  control = new FormControl();

  isEditing = false;

  items: ComponentType[];

  constructor(
    private service: ComponentTypeService
  ) {

    this.control.valueChanges.pipe(
      debounceTime(300),
      switchMap((value) => this.service.search({ name: value }))
    ).subscribe((page) => {
      this.items = page.items;
    });
  }

  ngOnInit() {
    this.userInput.nativeElement.value = this.toDisplayText(this.model);
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.model = event.option.value;
    this.changed.emit(this.model);
    this.userInput.nativeElement.value = this.toDisplayText(this.model);
    this.control.setValue(null);
  }

  toDisplayText(item?: ComponentType): string | undefined {
    return item ? item.name : '';
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { UxService } from '~/app/core/services';
import { RoleService } from '~/app/open-age/core/services';
import { IWizStep } from '~/app/open-age/core/structures/wiz/wiz-step.interface';
import { Employee, Organization } from '~/app/open-age/directory/models';
import { Task } from '~/app/open-age/gateway/models';
import { Workflow } from '~/app/open-age/gateway/models/workflow.model';
import { TaskService } from '~/app/open-age/gateway/services';
import { WorkflowsService } from '~/app/open-age/gateway/services/workflows.service';

@Component({
  selector: 'gateway-task-new',
  templateUrl: './new-task.component.html',
  styleUrls: ['./new-task.component.css']
})
export class NewTaskComponent implements OnInit, IWizStep {

  @Input()
  type: string;

  @Output()
  created: EventEmitter<any> = new EventEmitter();

  @Output()
  change: EventEmitter<Task> = new EventEmitter();

  task: Task;
  selectedWorkflow: Workflow;
  workflows: Workflow[];
  organization: Organization;
  selectedAssignee: Employee;
  isProcessing: boolean;

  isDescriptionSelected = false;

  code: string;
  icon: string;
  title: string;
  isSelected: boolean;
  isOpen: boolean;
  isComplete: boolean;
  required: boolean;
  hasError: boolean;
  isDisabled: boolean;

  constructor(
    private workflowsService: WorkflowsService,
    private taskService: TaskService,
    private roleService: RoleService,
    private uxService: UxService
  ) {
    this.task = new Task({});
  }

  ngOnInit() {
    this.getWorkflow();
    this.organization = this.roleService.currentOrganization();
  }

  getWorkflow() {
    this.workflowsService.search().subscribe((items) => {
      this.workflows = items.items;
      if (this.type) {
        this.selectedWorkflow = this.workflows.find((w) => w.code.toLowerCase() === this.type.toLowerCase());
      }
    });
  }

  onWorkflowSelect($event) {
    this.selectedWorkflow = $event;
    this.type = this.selectedWorkflow.code;
    this.change.emit(this.task);
  }

  assigneeSelected(employee: Employee) {
    this.selectedAssignee = employee;
  }

  dateSelected($event: Date) {
    this.task.plan.finish = $event;
    this.change.emit(this.task);
  }

  descriptionSelected($event) {
    // const text = this.uxService.getTextFromEvent($event, '');
    // if (!text) {
    //   return;
    // }
    this.task.description = $event;
    this.change.emit(this.task);
  }

  validate() {
    return !!(this.type &&
      this.task.size &&
      this.selectedAssignee);
  }

  complete() {
    const subject = new Subject<Task>();
    this.isProcessing = true;
    this.taskService.create({
      description: this.task.description,
      assignee: {
        code: this.selectedAssignee.code
      },
      size: this.task.size,
      priority: this.task.priority,
      project: {
        code: this.organization.code,
        name: this.organization.name
      },
      workflow: {
        code: this.type
      }
    }).subscribe((r) => {
      this.isProcessing = false;

      if (r) {
        this.task = new Task({});
        this.selectedAssignee = new Employee({});
        this.uxService.showInfo('Created');
        subject.next(r);
        this.created.emit(true);
      }
    });

    return subject.asObservable();
  }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material';
import * as moment from 'moment';
import { DateService } from '~/app/open-age/core/services/date.service';

// import { FormControl } from '@angular/forms';
// import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
// import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ngDevModeResetPerfCounters } from '@angular/core/src/render3/ng_dev_mode';
import { TimeLine } from '../../../open-age/gateway/models/timeline.model';

// export const MY_FORMATS = {
//   parse: {
//     dateInput: 'LL',
//   },
//   display: {
//     dateInput: 'LL',
//     monthYearLabel: 'MMM YYYY',
//     dateA11yLabel: 'LL',
//     monthYearA11yLabel: 'MMMM YYYY',
//   },
// };
@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css'],
  // providers: [
  //   {
  //     provide: DateAdapter,
  //     useClass: MomentDateAdapter,
  //     deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
  //   },

  //   { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  // ],
})
export class DatePickerComponent implements OnInit, OnChanges {

  @Input()
  view = 'date-input';

  @Input()
  placeholder = 'Date';

  @Input()
  date: string | Date;

  @Input()
  range: TimeLine;

  @Input()
  format = 'MM-dd-yyyy';

  @Input()
  disabled: boolean;

  @Input()
  isReset = false;

  @Input()
  isTouchUi: boolean = false;

  @Output()
  change: EventEmitter<Date | TimeLine> = new EventEmitter();

  @Output()
  select: EventEmitter<Date> = new EventEmitter();

  @ViewChild('picker')
  picker: MatDatepicker<Date>;

  dates: Date[] = [];

  month: string;

  // value: FormControl;

  constructor(
    private dateService: DateService
  ) { }

  ngOnInit() {

    //   // const date = this.dateService.parse(this.date);
    //   // this.setDates(date);

    //   // this.date = this.dates.find((i) => this.dateService.compare(i, date, 'date'));
    //   // this.date = moment(this.date).format('YYYY-MM');
  }

  ngOnChanges() {

    if (this.isReset === true) {
      this.date = null;
    }

    if (this.range) {
      this.view = 'range';
      return;
    }

    if (this.view == 'range') {
      this.range = new TimeLine({})
    }

    if (typeof this.date === 'string') {
      this.date = moment(this.date).toDate();
    }

    switch (this.view) {
      case 'week':
        this.setWeek(this.date);
        break;

      case 'month':
        this.month = this.dateService.date(this.date).toString('YYYY-MM');
        break;

      case 'day':
        this.date = this.date || new Date();
        break;
    }
  }

  setWeek(date: Date) {
    this.dates = this.dateService.inWeek(date);
    const lastDate = this.dates[this.dates.length - 1];
    this.month = this.dateService.toString(lastDate, 'month');
  }

  click(date: Date) {
    this.date = date;
    this.change.emit(date);
    this.select.emit(date);
  }

  showNextWeek() {
    const lastDate = this.dates[this.dates.length - 1];
    this.setWeek(this.dateService.nextDay(lastDate));
  }

  showPreviousWeek() {
    this.setWeek(this.dateService.previousDay(this.dates[0]));
  }

  showNextMonth() {
    this.date = moment(this.date).add(1, 'month').toDate();
    this.select.emit(this.date);
    this.change.emit(this.date);
  }

  showPreviousMonth() {
    this.date = moment(this.date).subtract(1, 'month').toDate();
    this.change.emit(this.date);
    this.select.emit(this.date);
  }

  showNextDate() {
    this.date = moment(this.date).add(1, 'day').toDate();
    this.select.emit(this.date);
    this.change.emit(this.date);
  }

  showPreviousDate() {
    this.date = moment(this.date).subtract(1, 'day').toDate();
    this.change.emit(this.date);
    this.select.emit(this.date);
  }

  updated($event) {
    this.change.emit(this.getDate($event));
    this.select.emit(this.getDate($event));
  }

  updatedStart($event) {
    this.range.start = this.getDate($event);
    this.change.emit(this.range);
  }

  updatedFinish($event) {
    this.range.finish = this.getDate($event);
    this.change.emit(this.range);
  }

  getDate($event) {
    if ($event.value) {
      return $event.value;
    }

    if ($event.currentTarget && $event.currentTarget.value) {
      return moment($event.currentTarget.value);
    }

    return null;
  }
}

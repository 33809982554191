import { LeaveType } from './../../ams/models/leave-type.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UxService } from '~/app/core/services';
import { Entity, Page } from '../../core/models';
import { RoleService } from '../../core/services';
import { Folder } from '../models';
import { Doc } from '../models/doc.model';
import { DriveApiBase } from './drive-api.base';

@Injectable()
export class DocsService extends DriveApiBase<Doc> {
  constructor(
    private httpClient: HttpClient,
    http: HttpClient,
    private auth: RoleService,
    uxService: UxService) {

    super('files', http, auth, uxService);
  }

  public searchByEntity(entity: Entity, folder?: Folder | string, fileName?: string, options?: any, params?: any, fileCode?: string): Observable<Page<Doc>> {
    const query = params || {}

    if (entity && (entity.type || entity.id)) {
      query['entity-type'] = entity.type
      query['entity-id'] = entity.id
    }

    if (folder) {
      if (typeof folder === 'string') {
        query['folder-name'] = folder;
      } else if (folder.id) {
        query['folder-id'] = folder.id;
      } else if (folder.name) {
        query['folder-name'] = folder.name;
      }

      if (typeof folder !== 'string') {
        if (folder.owner) {
          query['owner'] = folder.owner;
          query['folder-owner'] = folder.owner;
        }
      }
    }

    if (fileName) {
      query['name'] = fileName;
    }

    if (fileCode) {
      query['code'] = fileCode;
    }

    return this.search(query, options);
  }

  public getByEntity(entity: Entity, name: string): Observable<Doc> {
    return this.get(`${name}?entity-type=${entity.type}&entity-id=${entity.id}`);
  }

  public getLayout(code: string, tenantCode: string, ownerCode?: string): Observable<Doc> {
    if (!ownerCode) {
      ownerCode = 'system';
    }
    return this.get(`layout.json?folder-code=${code}&owner-code=${ownerCode}&tenant-code=${tenantCode}`);
  }

  public createByEntity(entity: Entity, file: File, specs?: Doc, folder?: Folder | string): Observable<Doc> {

    const queryParams = {}

    if (entity && (entity.type || entity.id)) {
      queryParams['entity-type'] = entity.type
      queryParams['entity-id'] = entity.id
    }
    queryParams['owner'] = "system";
    queryParams['isPublic'] = "true";

    if (specs.name) {
      queryParams['name'] = specs.name;
    }

    if (specs.orderNo || specs.orderNo == 0) {
      queryParams['orderNo'] = specs.orderNo;
    }

    if (specs.id) {
      queryParams['id'] = specs.id;
    }

    if (specs.description) {
      queryParams['description'] = specs.description;
    }
    if (specs.code) {
      queryParams['code'] = specs.code;
    }
    if (specs.status) {
      queryParams['status'] = specs.status;
    }
    if (specs.timemark) {
      queryParams['timemark'] = specs.timemark;
    }
    if (specs.overwrite || specs.overwrite == false) {
      queryParams['overwrite'] = specs.overwrite;
    }
    if (specs.meta && specs.meta.duration) {
      queryParams['meta-duration'] = specs.meta.duration;
    }
    if (folder) {
      if (typeof folder === 'string') {
        queryParams['folder-name'] = folder;
      } else {
        if (folder.id) {
          queryParams['folder-id'] = folder.id;
        } else if (folder.name) {
          queryParams['folder-name'] = folder.name;
        }
        if (folder.owner) {
          queryParams['owner'] = folder.owner;
          queryParams['folder-owner'] = folder.owner;
        }
      }
      return this.upload(file, null, queryParams);
    }
  }

  public downloadPdf(code: string, id: string, query: any) {
    let url = `${this.getRoot()}/docs/${code}/${id}.pdf`;

    if (query) {
      url += `?${new URLSearchParams(query).toString()}`
    }
    return this.httpClient.get(url, {
      observe: 'response', responseType: 'blob'
    }).pipe(map((res) => {
      return new Blob([res.body], { type: res.headers.get('Content-Type') });
    }));
  }


  private getRoot(): string {
    const tenant = this.auth.currentTenant();

    if (tenant) {
      const service = tenant.services.find((i) => i.code === 'drive');

      if (service && service.url) {
        return service.url;
      }
    }

    // return root;
  }
}

import { state } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { UxService } from '../../../core/services/ux.service';
import { Entity } from '../../core/models';
import { RoleService } from '../../core/services';
import { Doc } from '../../drive/models';
import { DocsService } from '../../drive/services';
import { Category, Member, Sprint, TimeLog, User } from '../models';
import { Slide } from '../models/slide.model';
import { State } from '../models/state.model';
import { Task } from '../models/task.model';
import { TimeLine } from '../models/timeline.model';
import { GatewayApi } from './gateway.api';

@Injectable()
export class TaskService extends GatewayApi<Task> {
  constructor(
    http: HttpClient,
    private auth: RoleService,
    private uxService: UxService,
    private docsService: DocsService
  ) {
    super('tasks', http, auth, uxService);
  }

  calculateProgress(issue: Task) {
    if (issue.status === 'done') {
      return 100;
    }
    if (!issue.burnt && issue.status === 'wip') {
      return 20;
    }
    if (!issue.points || !issue.burnt) {
      return 0;
    }
    return 100 * issue.points / issue.burnt;
  }

  updateSubject(issue: Task, subject: string): Observable<string> {

    const updateSubject = new Subject<string>();
    const oldSubject = issue.subject;
    issue.subject = subject;
    issue.isProcessing = true;
    this.update(issue.id, issue).subscribe(() => {
      issue.isProcessing = false;
      updateSubject.next(subject);
    }, (err) => {
      issue.subject = oldSubject;
      issue.isProcessing = false;
      this.uxService.handleError(err.message || err);
      updateSubject.next(oldSubject);
    });
    return updateSubject;
  }

  updateDescription(issue: Task, description: string): Observable<string> {

    const updateSubject = new Subject<string>();
    const oldDescription = issue.description;
    issue.description = description;
    issue.isProcessing = true;
    this.update(issue.id, issue).subscribe(() => {
      issue.isProcessing = false;
      issue.attachments = this.getAttachments(issue);
      updateSubject.next(description);
    }, (err) => {
      issue.description = oldDescription;
      issue.isProcessing = false;
      this.uxService.handleError(err.message || err);
      updateSubject.next(oldDescription);
    });
    return updateSubject;
  }

  updatePoints(issue: Task, points: number): Observable<number> {

    const updateSubject = new Subject<number>();
    const oldPoints = issue.points;
    issue.points = points;
    issue.isProcessing = true;
    this.update(issue.id, issue).subscribe(() => {
      issue.isProcessing = false;
      updateSubject.next(points);
    }, (err) => {
      issue.points = oldPoints;
      issue.isProcessing = false;
      this.uxService.handleError(err.message || err);
      updateSubject.next(oldPoints);
    });
    return updateSubject;
  }

  updateStatus(issue: Task, status: State): Observable<State> {

    const subject = new Subject<State>();
    const oldStatus = issue.currentStatus;
    const oldBurnt = issue.burnt;
    issue.currentStatus = issue.workflow.states.find((s) => s.code === status.code);

    if (status.isFinal) {
      if (status.isCancelled) {
        issue.burnt = 0;
        issue.points = 0;
      } else {
        issue.burnt = issue.points;
      }
    }
    issue.isProcessing = true;
    this.update(issue.id, {
      status: { code: status.code }
    }).subscribe(() => {
      issue.isProcessing = false;
      subject.next(status);
    }, (err) => {
      issue.currentStatus = oldStatus;
      issue.burnt = oldBurnt;
      issue.isProcessing = false;
      this.uxService.handleError(err.message || err);
      subject.next(oldStatus);
    });
    return subject;
  }

  updatePriority(issue: Task, priority: string): Observable<string> {
    const updateSubject = new Subject<string>();
    const oldPriority = issue.priority;
    const oldBurnt = issue.burnt;
    issue.priority = priority;
    issue.isProcessing = true;
    this.update(issue.id, issue).subscribe(() => {
      issue.isProcessing = false;
      updateSubject.next(priority);
    }, (err) => {
      issue.priority = oldPriority;
      issue.isProcessing = false;
      this.uxService.handleError(err.message || err);
      updateSubject.next(oldPriority);
    });
    return updateSubject;
  }
  updatePlan(issue: Task, plan: TimeLine): Observable<TimeLine> {
    const updateSubject = new Subject<TimeLine>();
    this.update(issue.id, {
      plan
    }).subscribe((i) => {
      issue.isProcessing = false;
      issue.plan = i.plan;
      issue.effort = i.effort;
      updateSubject.next(issue.plan);
    }, (err) => {
      issue.isProcessing = false;
      this.uxService.handleError(err.message || err);
      updateSubject.next(issue.plan);
    });

    return updateSubject;
  }

  updateEffort(issue: Task, effort: number): Observable<number> {
    const updateSubject = new Subject<number>();
    this.update(issue.id, {
      effort
    }).subscribe((i) => {
      issue.isProcessing = false;
      issue.plan = i.plan;
      issue.effort = i.effort;
      updateSubject.next(issue.effort);
    }, (err) => {
      issue.isProcessing = false;
      this.uxService.handleError(err.message || err);
      updateSubject.next(issue.effort);
    });

    return updateSubject;
  }
  getAttachments = (item: Task | string): Doc[] => {
    const attachments: Doc[] = [];

    let text = '';

    if (typeof item === 'string') {
      text = item;
    } else {
      text = item.description || '';
    }

    if (!text) {
      return attachments;
    }
    const urls = text.match(/\b(https?:\/\/\S+(?:png|jpe?g|gif)\s*)\b/g);
    if (!urls) {
      return attachments;
    }
    urls.forEach((url) => {
      const attachment = new Doc();
      attachment.url = url;
      attachment.thumbnail = url;
      if (url.endsWith('.jpg') || url.endsWith('.jpeg')) {
        attachment.type = 'image/jpg';
      } else if (url.endsWith('.png')) {
        attachment.type = 'image/png';
      } else if (url.endsWith('.gif')) {
        attachment.type = 'image/gif';
      }
      attachments.push(attachment);
    });

    return attachments;
  }

  getSlides(item: Task): Slide[] {
    const slides: Slide[] = [];
    const sections = (item.description && item.description !== '<div>---</div>')
      ? item.description.split('<div>---</div>') : [''];

    const storySections = [];
    sections.forEach((i) => {
      i.split('---').forEach((subItem) => storySections.push(subItem));
    });
    let index = 0;
    storySections.forEach((section) => {
      const attachments = this.getAttachments(section);
      const slide = new Slide({
        id: index,
        description: section
      });

      index++;

      slides.push(slide);
      if (attachments && attachments.length) {
        attachments.forEach((attachment) => {
          let url = attachment.url;
          url = url.replace('</span>', '');
          url = url.replace('<span>', '');
          url = url.replace('/blob/', '/raw/');
          if (url.endsWith('.png') || url.endsWith('.jpg') || url.endsWith('.jpeg') || url.endsWith('.gif')) {
            slide.imageUrl = url;
          }
        });
      }
    });

    return slides;
  }

  updateCategory(issue: Task, category: Category): Observable<Category> {

    const updateSubject = new Subject<Category>();
    const oldCategory = issue.category;

    issue.category = category;
    issue.isProcessing = true;

    this.update(issue.id, issue).subscribe(() => {
      issue.isProcessing = false;
      updateSubject.next(category);
    }, (err) => {
      issue.category = oldCategory;
      issue.isProcessing = false;
      this.uxService.handleError(err.message || err);
      updateSubject.next(oldCategory);
    });
    return updateSubject;
  }

  updateUser(issue: Task, user: User): Observable<User> {

    const updateSubject = new Subject<User>();
    const oldUser = issue.user;

    issue.user = user;
    issue.isProcessing = true;

    this.update(issue.id, issue).subscribe(() => {
      issue.isProcessing = false;
      updateSubject.next(user);
    }, (err) => {
      issue.user = oldUser;
      issue.isProcessing = false;
      this.uxService.handleError(err.message || err);
      updateSubject.next(oldUser);
    });
    return updateSubject;
  }

  updateSprint(issue: Task, sprint: Sprint): Observable<Sprint> {

    const updateSubject = new Subject<Sprint>();
    const oldSprint = issue.sprint;

    issue.sprint = sprint;
    issue.isProcessing = true;

    this.update(issue.id, issue).subscribe(() => {
      issue.isProcessing = false;
      updateSubject.next(sprint);
    }, (err) => {
      issue.sprint = oldSprint;
      issue.isProcessing = false;
      this.uxService.handleError(err.message || err);
      updateSubject.next(oldSprint);
    });
    return updateSubject;
  }

  attach(issue: Task, file): Observable<Doc> {
    issue.isProcessing = true;
    const subject = new Subject<Doc>();
    this.docsService.createByEntity(new Entity({
      id: issue.id,
      type: 'issue'
    }), file).subscribe((doc) => {
      issue.isProcessing = false;
      subject.next(doc);

      // const imageUrl = doc.url;
      // if (issue && imageUrl) {
      //   this.updateDescription(issue, `${issue.description || ''}<div><img class="thumbnail" src="${imageUrl}"></div>`).subscribe(() => {
      //     issue.isProcessing = false;
      //     subject.next(doc);
      //   }, (err) => {
      //     issue.isProcessing = false;
      //     subject.error(err);
      //     this.uxService.handleError(err);
      //   } );
      // }
    }, (err) => {
      issue.isProcessing = false;
      subject.error(err);
      this.uxService.handleError(err.message || err);
    });

    return subject.asObservable();
  }

  logTime(issue: Task, timeLog: TimeLog) {
    timeLog.task = issue;
    issue.isProcessing = true;
    return this.post(timeLog, `${issue.id}/timeLogs`).subscribe(() => {
      issue.isProcessing = false;
    }, (err) => {
      issue.isProcessing = false;
      this.uxService.handleError(err.message || err);
    });
  }

  refresh(issue) {
    return this.get(issue.id).subscribe((i) => {
      issue.effort = i.effort;
      issue.isProcessing = false;
    }, (err) => {
      issue.isProcessing = false;
      this.uxService.handleError(err.message || err);
    });
  }
}

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material';
import { debounceTime, switchMap } from 'rxjs/operators';
import { Batch } from '~/app/open-age/directory/models';
import { BatchService } from '~/app/open-age/directory/services';

@Component({
  selector: 'directory-batch-picker',
  templateUrl: './batch-picker.component.html',
  styleUrls: ['./batch-picker.component.css']
})
export class BatchPickerComponent implements OnInit {

  @Input()
  batch: Batch;

  @Input()
  readonly = false;

  @Input()
  required = false;

  @Input()
  placeholder = 'Division';

  @Output()
  changed: EventEmitter<Batch> = new EventEmitter();

  @ViewChild('userInput')
  userInput: ElementRef<HTMLInputElement>;

  @ViewChild('divisionPicker')
  matAutocomplete: MatAutocomplete;

  control = new FormControl();

  isEditing = false;

  items: Batch[];

  constructor(
    private api: BatchService
  ) {

    this.control.valueChanges.pipe(
      debounceTime(300),
      switchMap((value) => this.api.search({ name: value }))
    ).subscribe((page) => {
      this.items = page.items;
    });
  }

  ngOnInit() {
    // if (this.usercode !== 'my') {
    //   this.userInput.nativeElement.value = this.displayBatch(this.division);
    // }
  }

  displayBatch(item?: Batch): string | undefined {
    if (!item) {
      return '';
    }

    if (typeof item === 'string') {
      return item;
    }

    if (!item.name || !item.name) {
      return item.code;
    }

    return `${item.name}`;
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.batch = event.option.value;

    this.changed.emit(this.batch);

    this.userInput.nativeElement.value = this.displayBatch(this.batch);
    this.control.setValue(null);
  }
}

import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as Chart from 'chart.js';
import { WidgetBaseComponent } from '~/app/open-age/insight/components/widget.base.component';
import { UxService } from '../../../../core/services/ux.service';
import { ReportTypeService } from '../../../../open-age/insight/services/report-type.service';
import { WidgetDataService } from '../../../../open-age/insight/services/widget-data.service';

@Component({
  selector: 'insight-graph-widget',
  templateUrl: './graph-widget.component.html',
  styleUrls: ['./graph-widget.component.css']
})
export class GraphWidgetComponent extends WidgetBaseComponent {
  @Input()
  type: string;

  chart: any

  @ViewChild('myCanvas') myCanvas: ElementRef;

  public context: CanvasRenderingContext2D;

  data = {
    labels: [],
    datasets: []
  };
  scales: {};
  constructor(api: WidgetDataService, reportTypeService: ReportTypeService, uxService: UxService, private elementRef: ElementRef) {
    super(api, reportTypeService, uxService);

    this.afterProcessing = () => {
      let datasets = [];
      const headArray = this.reportType.columns.map((c) => c.label);
      this.items.forEach((item) => {
        datasets = [];
        const dataset = {
          dataArray: [],
          colorArray: [],
          borderColorArray: []
        };
        this.reportType.columns.forEach((column) => {
          // tslint:disable-next-line:radix
          dataset.dataArray.push(parseInt(item[column.key]));
          if (column.style) {
            if (column.style.background) {
              dataset.colorArray.push(column.style.background);
            }
            if (column.style.border && column.style.border.color) {
              dataset.borderColorArray.push(column.style.border.color);
            }
          }
        });
        datasets.push({
          label: this.reportType.name,
          data: dataset.dataArray,
          backgroundColor: dataset.colorArray,
          borderColor: dataset.borderColorArray,
          borderWidth: 1
        });
      });

      this.data = {
        labels: headArray,
        datasets
      };

      if (this.type === 'pie' || this.type === 'doughnut') {
        const xAxes = [{
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: false,
          }
        }];
        const yAxes = [{
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: false,
          }
        }];
        this.scales = {
          xAxes,
          yAxes
        };
      } else {
        this.scales = {
          xAxes: [{
            gridLines: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: false,
            }
          }],
          yAxes: [{
            gridLines: {
              display: true
            },
            ticks: {
              display: true,
            }
          }]
        };
      }

      if (!this.isProcessing && this.data.labels.length > 0 && this.data.datasets.length > 0) {
        this.context = (<HTMLCanvasElement>this.myCanvas.nativeElement).getContext('2d');

        if (this.chart && this.chart.destroy) {
          this.chart.destroy();
        }
        
        this.chart = new Chart(this.context, {
          type: this.type,
          data: this.data,
          options: {
            scales: this.scales
          }
        });
      }

    };
  }
}

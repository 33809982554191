import { CuresReportService } from '~/app/open-age/telepain/services/cures-report.service';
import { MatSlideToggleChange, MatDialog } from '@angular/material';
import { Address } from '~/app/open-age/core/models';
import { Component, OnInit, ErrorHandler, Input } from '@angular/core';
import { PatientService } from '~/app/open-age/telepain/services/patient.service';
import { Patient } from '~/app/open-age/telepain/models/patient';
import * as moment from 'moment';
import { Link, PagerBaseComponent } from '~/app/open-age/core/structures';
import { NavService, UxService } from '~/app/core/services';
import { ActivatedRoute } from '@angular/router';
import { PatientImportComponent } from '~/app/patients/components/patient-import/patient-import.component';
import { Profile } from '~/app/open-age/ams/models/profile.model';



@Component({
  selector: 'app-practice-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.css']
})
export class PatientListComponent extends PagerBaseComponent<Patient> implements OnInit {

   @Input()
  practiceCode: Array<Patient> = [];
  viewType: 'list' | 'view' | 'edit' | 'add' = 'list';

  displayedColumns: string[] = ['id', 'name', 'gender', 'age', 'dob', 'email', 'phone', 'status'];

  patients: Array<Patient> = [];
  selectedPatient: Patient;

  searchText: string = '';
  searchStatus: 'active' | 'all' = 'active';
  isCurrent = true;
  page: Link;

  constructor(
    private reportService: CuresReportService,
    private uxService: UxService,
    public dialog: MatDialog,
    private api: PatientService,
    errorHandler: ErrorHandler,
    private navService: NavService,
    private route: ActivatedRoute,
  ) {
    super({
      api,
      errorHandler,
      filters: [
        'text',
        'dob',
        'status',
        'practiceCode'
      ],
      pageOptions: {
        limit: 3,
      },
    });
    // this.afterFetching = () => {
    //   this.items.forEach((item, index) => {
    //     item.phone = this.phoneNumberFormat(item.phone);
    //     item.phoneHome = this.phoneNumberFormat(item.phoneHome);
    //   })
    // }
  }

  ngOnInit() {
    this.onSearch();
  }

  public calculateAge(birthday): number {    // birthday is a date
    var ageDifMs = Date.now() - new Date(birthday).getTime();
    var ageDate = new Date(ageDifMs);             // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  public onSearch(): void {
    this.currentPageNo = 1;
    if (this.isValidDate(this.searchText)) {
      // const dob = this.searchText.split("-").reverse().join("-");
      this.filters.set('dob', this.searchText);
      this.filters.set('text', '');
    } else {
      this.filters.set('dob', '');
      this.filters.set('text', this.searchText);
    }

    if (this.searchStatus && !this.searchText) {
      this.filters.set('status', this.searchStatus);
    } else {
      this.filters.set('status', '');
    }

    if (this.searchText) {
      this.filters.set('status', 'all');
    }
    if (this.practiceCode) {
      this.filters.set('practiceCode', this.practiceCode);
    }

    this.fetch().subscribe();
  }

  public searchByStatus(value: boolean) {
    this.searchText = '';
    this.searchStatus = value ? 'all' : 'active';
    this.onSearch();
  }

  public onSelectPatient(patient: Patient, viewType): void {
    this.selectedPatient = new Patient(patient);
    this.viewType = viewType;
  }

  public onAddNew() {
    this.selectedPatient = new Patient({
      status: 'active',
      profile: new Profile({}),
      address: new Address({})
    });
    this.viewType = 'add';
  }


  public isValidDate(text: string): boolean {
    return moment(text, "M/D/YYYY", true).isValid();
  }

  public onDelete(patient: Patient, status: string) {
    this.api.remove(patient.id).subscribe(() => {
      this.refresh();
    });
  }

  public onActivate(patient: Patient, status: string) {
    this.api.update(patient.id, { status }).subscribe(() => {
      this.refresh();
    });
  }

  public onToggle(patient: Patient) {
    if (patient.status == 'active') {
      patient.status = 'all'
    } else {
      patient.status = 'active'
    }
    this.api.update(patient.id, { status: patient.status }).subscribe(() => {
      this.refresh();
    });
  }

  public viewReport(patient: Patient): void {
    this.navService.goto(`/patients/${patient.id}/reports`)
  }

  public refresh(): void {
    this.viewType = 'list';
    this.onSearch();
  }

  public onImport(): void {
    const dialogRef = this.dialog.open(PatientImportComponent);
    dialogRef.afterClosed().subscribe((val: boolean) => {
      if (val) {
        this.onSearch();
      }
    });
  }

  phoneNumberFormat(mobile) {
    if (!mobile) {
      return
    }
    let phoneNumber = mobile
    let phoneNumberArray
    phoneNumber = phoneNumber.replace(/[^0-9]*/g, '')
    if (phoneNumber) {
      phoneNumberArray = phoneNumber.split('')
      phoneNumber = ''
      phoneNumberArray.forEach((item, i) => {
        if (i < 2) { phoneNumber = phoneNumber + item }
        if (i == 2) { phoneNumber = phoneNumber + item + '-' }
        if ((i < 5) && (i > 2)) { phoneNumber = phoneNumber + item }
        if (i == 5) { phoneNumber = phoneNumber + item + '-' }
        if (i > 5 && i < 11) { phoneNumber = phoneNumber + item }
      });
    }
    return phoneNumber;
  }

  public generateReport(): void {
    this.isProcessing = true;
    this.reportService.create({}).subscribe((response) => {
      this.isProcessing = false;
      this.uxService.showInfo('Generating Report.')
    }, (error) => {
      this.isProcessing = false;
    })
  }

}



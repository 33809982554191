import { Component, ErrorHandler, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import * as moment from 'moment';
import { UxService } from '~/app/core/services';
import { CalendarEvent } from '~/app/open-age/insight/models/calendar-event.model';
import { CalenderDayDetailComponent } from '../calender-day-detail/calender-day-detail.component';

@Component({
  selector: 'insight-calendar-date',
  templateUrl: './calendar-date.component.html',
  styleUrls: ['./calendar-date.component.css']
})
export class CalendarDateComponent implements OnInit {
  selectedDate = moment();

  @Input()
  date: Date;

  @Input()
  events: CalendarEvent[];

  dateForm: FormGroup;

  isReserved = null;

  daysArr: Day[];

  @Output()
  dateChange: EventEmitter<Date> = new EventEmitter<Date>();

  isSelectDay = false;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public uxService: UxService
  ) {
    this.initDateRange();
    this.selectedDate = moment(this.date);
  }

  initDateRange() {
    return (this.dateForm = this.fb.group({
      dateFrom: [null, Validators.required],
      dateTo: [null, Validators.required]
    }));
  }

  ngOnInit() {
    this.selectedDate = moment(this.date);
    this.daysArr = this.createCalendar(this.selectedDate);
    this.setEventsToCalendar(this.events);
    console.log(this.events);
  }

  createCalendar(date) {
    const firstMonthDay = moment(date).startOf('M');
    const days = Array.apply(null, { length: date.daysInMonth() })
      .map(Number.call, Number)
      .map((n) => {
        return new Day({
          date: moment(firstMonthDay).add(n, 'd'),
          status: 'enabled'
        });
      });

    for (let n = 0; n < firstMonthDay.weekday(); n++) {
      days.unshift(new Day({
        date: moment(firstMonthDay).subtract(n + 1, 'd'),
        status: 'disabled'
      }));
    }

    const lastMonthDay = moment(date).endOf('M');

    for (let m = 0; m < 6 - lastMonthDay.weekday(); m++) {
      days.push(new Day({
        date: moment(firstMonthDay).add(m, 'd'),
        status: 'disabled'
      }));
    }

    return days;
  }

  nextMonth() {
    this.selectedDate.add(1, 'M');
    this.daysArr = this.createCalendar(this.selectedDate);
    this.dateChange.emit(this.selectedDate.toDate());
  }

  previousMonth() {
    this.selectedDate.subtract(1, 'M');
    this.daysArr = this.createCalendar(this.selectedDate);
    this.dateChange.emit(this.selectedDate.toDate());
  }

  todayCheck(day) {
    if (!day || !day.date || day.status !== 'enabled') {
      return false;
    }

    return moment().format('L') === day.date.format('L');
  }

  isSelected(day) {
    if (!day) {
      return false;
    }
    const dateFromMoment = moment(this.dateForm.value.dateFrom, 'MM/DD/YYYY');
    const dateToMoment = moment(this.dateForm.value.dateTo, 'MM/DD/YYYY');
    if (this.dateForm.valid) {
      return (
        dateFromMoment.isSameOrBefore(day) && dateToMoment.isSameOrAfter(day)
      );
    }
    if (this.dateForm.get('dateFrom').valid) {
      return dateFromMoment.isSame(day);
    }
  }

  setEventsToCalendar(events) {

    this.daysArr = this.createCalendar(this.selectedDate);

    events.forEach((event) => {

      function getEventDay(day) {
        return day.date.isSame(event.date, 'day');
      }

      const day = this.daysArr.find(getEventDay);

      if (day) {
        day.events = day.events || [];
        day.events.push(event);
      }

    });
  }

  selectDay(events) {
    this.isSelectDay = true;
    events.forEach((event) => {

      function getEventDay(day) {
        return day.date.isSame(event.date, 'day');
      }

      const day = this.daysArr.find(getEventDay);

      if (day) {
        day.events = day.events || [];
        day.events.push(event);
      }

    });
  }

  openDialog(day): void {
    if (day.events === undefined) {
      this.uxService.handleError('No Holiday Today');
      return;
    }
    const instance = this.uxService.openDialog(CalenderDayDetailComponent).componentInstance;
    instance.event = day.events;
  }
}

// tslint:disable-next-line:max-classes-per-file
class Day {
  date: Date;
  status: 'enabled' | 'disabled' = 'enabled';
  events: CalendarEvent[];

  constructor(obj?: any) {
    this.date = obj.date;
    this.status = obj.status;

    if (obj.events && obj.events.length) {
      this.events = obj.events.map((e) => new CalendarEvent(e));
    }
  }
}

import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material';
import { Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { Employee } from 'src/app/open-age/directory/models';
import { EmployeeService } from 'src/app/open-age/directory/services';
import { Page } from '~/app/open-age/core/models';

@Component({
  selector: 'directory-employee-picker',
  templateUrl: './employee-picker.component.html',
  styleUrls: ['./employee-picker.component.css'],
})
export class EmployeePickerComponent implements OnInit, OnChanges {

  @Input()
  readonly = false;

  @Input()
  required = false;

  @Input()
  code: string;

  @Input()
  reset = false;

  @Input()
  employee: Employee;

  @Input()
  placeholder: string;

  @Output()
  changed: EventEmitter<Employee> = new EventEmitter();

  @ViewChild('userInput')
  userInput: ElementRef<HTMLInputElement>;

  @ViewChild('employeePicker')
  matAutocomplete: MatAutocomplete;

  control = new FormControl();

  isEditing = false;
  isProcessing = false;

  items: Employee[];

  constructor(
    private employeeService: EmployeeService
  ) {

    this.control.valueChanges.pipe(
      debounceTime(300),
      switchMap((value) => {
        this.isProcessing = true;
        const subject = new Subject<Page<Employee>>();
        this.employeeService.search({ name: value }, { limit: 10 }).subscribe((p) => {
          subject.next(p);
          this.isProcessing = false;
        }, (err) => {
          this.isProcessing = false;
        });

        return subject.asObservable();

      })
    ).subscribe((page) => {
      this.items = page.items;
    });
  }

  ngOnInit() {
    if (this.employee) {
      this.userInput.nativeElement.value = this.displayEmployee(this.employee);
    } else if (this.code) {
      this.employeeService.get(this.code).subscribe((e) => {
        this.employee = e;
        this.userInput.nativeElement.value = this.displayEmployee(e);
      });
    }
  }

  ngOnChanges() {
    if (this.code === 'my') {
      this.readonly = true;
      this.required = false;
    }
    if (this.reset) {
      this.employee = null;
    }
  }

  displayEmployee(employee?: Employee): string | undefined {
    if (!employee) {
      return '';
    }

    if (typeof employee === 'string') {
      return employee;
    }

    if (!employee.profile || !employee.profile.firstName) {
      return employee.code;
    }

    return `${employee.profile.firstName ? employee.profile.firstName : ''} ${employee.profile.lastName ? employee.profile.lastName : ''} (${employee.code ? employee.code : ''})`;
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.employee = event.option.value;

    this.changed.emit(this.employee);

    this.userInput.nativeElement.value = this.displayEmployee(this.employee);
    this.control.setValue(null);
  }
}

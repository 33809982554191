import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-status-selector',
  templateUrl: './status-selector.component.html',
  styleUrls: ['./status-selector.component.css']
})
export class StatusSelectorComponent implements OnInit {

  @Input()
  selected: {};

  @Input()
  reset = false;

  @Output()
  change: EventEmitter<string[]> = new EventEmitter();

  data = [];
  settings = {};
  selectedItem: string[] = [];
  selectedStatus: string[] = [];

  constructor() { }

  ngOnInit() {
    this.data.push({
      id: '1',
      itemName: 'Present',
      code: 'present'
    });
    this.data.push({
      id: '2',
      itemName: 'Absent',
      code: 'absent'
    });
    this.data.push({
      id: '3',
      itemName: 'Half Day',
      code: 'halfDay'
    });
    this.data.push({
      id: '4',
      itemName: 'On Leave',
      code: 'onLeave'
    });
    this.data.push({
      id: '5',
      itemName: 'Week Off',
      code: 'weekOff'
    });
  }

  ngOnChanges() {
    if (this.reset === true) {
      this.selected = null;
      this.reset = false;
      this.selectedItem = [];
      this.selectedStatus = [];
    }
    this.settings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      enableSearchFilter: true,
      maxHeight: 200,
      badgeShowLimit: 1
    };
  }

  onSelect($event) {
    if (this.selectedItem === null) {
      this.selectedItem = [];
    }
    this.selectedStatus.push($event.code);
    this.selectedItem.push(`'` + $event.code + `'`);
    this.change.emit(this.selectedItem);
  }

  onDeSelect($event) {
    this.selectedItem = [];
    this.selectedStatus = this.selectedStatus.filter((item) => item !== $event.code);
    this.selectedStatus.forEach((item) => [
      this.selectedItem.push(`'` + item + `'`)
    ]);
    if (this.selectedItem.length < 1) {
      this.selectedItem = null;
    }
    this.change.emit(this.selectedItem);
  }

  onSelectAll(items: any) {
    this.selectedItem = [];
    items.forEach((vendor) => {
      this.selectedItem.push(vendor.code);
    });
    this.change.emit(this.selectedItem);
  }
  onDeSelectAll(items: any) {
    this.selectedItem = null;
    this.change.emit(this.selectedItem);
  }

}

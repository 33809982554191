import { Component, OnInit } from '@angular/core';
import { UxService } from '~/app/core/services/ux.service';
import { ValidationService } from '~/app/core/services/validation.service';
import { Organization, Division } from '~/app/open-age/directory/models';
import { Organization as WelcomeOrganization } from '~/app/open-age/welcome/models/organization.model';
import { OrganizationService as WelcomeOrganizationService } from '~/app/open-age/welcome/services';
import { OrganizationService, DivisionService } from '~/app/open-age/directory/services';
import { Link } from '~/app/open-age/core/structures';
import { NavService } from '~/app/core/services';
import { ActivatedRoute } from '@angular/router';
import { PracticeService } from '~/app/open-age/telepain/services/practice.service';

@Component({
  selector: 'app-practice',
  templateUrl: './practice.component.html',
  styleUrls: ['./practice.component.css']
})
export class PracticeComponent implements OnInit {

  practice: Organization;
  welcomePractice: WelcomeOrganization;
  divisions: Division[];
  isProcessing: boolean;
  id: string;
  isCurrent = true;
  page: Link;
  states = ["Alabama (AL)", "Alaska (AK)", "Arizona (AZ)", "Arkansas (AR)", "California (CA)",
    "Colorado (CO)", "Connecticut (CT)", "Delaware (DE)", "District of Columbia (DC)",
    "Florida (FL)", "Georgia (GA)", "Hawaii (HI)", "Idaho (ID)", "Illinois (IL)",
    "Indiana (IN)", "Iowa (IA)", "Kansas (KS)", "Kentucky (KY)", "Louisiana (LA)",
    "Maine (ME)", "Marshall Islands (MH)", "Maryland (MD)", "Massachusetts (MA)",
    "Michigan (MI)", "Minnesota (MN)", "Mississippi (MS)", "Missouri (MO)", "Montana (MT)",
    "Nebraska (NE)", "Nevada (NV)", "New Hampshire (NH)", "New Jersey (NJ)", "New Mexico (NM)",
    "New York (NY)", "North Carolina (NC)", "North Dakota (ND)", "Ohio (OH)", "Oklahoma (OK)",
    "Oregon (OR)", "Pennsylvania (PA)", "Rhode Island (RI)", "South Carolina (SC)",
    "South Dakota (SD)", "Tennessee (TN)", "Texas (TX)", "Utah (UT)", "Vermont (VT)",
    "Virginia (VA)", "Washington (WA)", "West Virginia (WV)", "Wisconsin (WI)",
    "Wyoming (WY)"]

  constructor(
    private organizationService: OrganizationService,
    private errorHandler: UxService,
    private validator: ValidationService,
    private navService: NavService,
    private route: ActivatedRoute,
    private divisionService: DivisionService,
    private practiceService: PracticeService,
    private welcomeOrganizationService: WelcomeOrganizationService
  ) {
  }

  ngOnInit() {
    this.page = this.navService.register(`/practice/list/:id`, this.route, (isCurrent, params) => {
      this.id = params.get("id")
      this.isCurrent = isCurrent;
      if (this.isCurrent) {
        this.setContext();
      }
    });
    if (this.id) {
      this.isProcessing = true
      this.organizationService.get(this.id).subscribe((item) => {
        this.practice = new Organization(item)
        this.welcomeOrganizationService.get(this.practice.code).subscribe((item) => {
          this.welcomePractice = new WelcomeOrganization(item)
        }, (error) => {
        })
        this.practicePhoneNumberFormat(this.practice.phone)
        this.practiceFaxNumberFormat(this.practice.meta.fax)
        this.divisionService.search({ organization: item.id }).subscribe(page => {
          this.divisions = page.items
          let i = 0
          for (const division of this.divisions) {
            if (division.meta && division.meta.phone) {
              this.divisionPhoneNumberFormat(division.meta.phone, i)
              this.divisionFaxNumberFormat(division.meta.fax, i)
            }
            i++
          }
          this.isProcessing = false
        }, (error) => {
          this.errorHandler.handleError("Locations Not Found");
          this.isProcessing = false;
          this.back()
        })
      }, (error) => {
        this.errorHandler.handleError("Practice Not Found");
        this.isProcessing = false;
        this.back()
      })
    } else {
      this.errorHandler.handleError("Practice Id Required");
      this.back()
    }
  }

  update() {
    if (!this.practice.email) {
      // this.errorHandler.handleError("Email is required")
      return
    }
    if (!this.practice.address.location) {
      // this.errorHandler.handleError("Practice location is required")
      return
    }

    if (!this.practice.name) {
      // this.errorHandler.handleError("Name is required")
      return
    }
    if (!this.practice.address.city) {
      // this.errorHandler.handleError("City is required")
      return
    }
    if (this.practice.email) {
      if (this.validator.isEmailValid(this.practice.email)) {
        // this.errorHandler.handleError("Invalid Email")
        return
      }
    }

    if (!this.practice.phone) {
      // this.errorHandler.handleError("Phone is required")
      return
    } else {
      this.practice.phone = this.practice.phone.replace(/-/g, "")
    }
    if (this.practice.phone && this.practice.phone.length != 10) {
      // this.errorHandler.handleError("Invalid Phone")
      return
    }

    if (this.practice.phone) {
      if (this.validator.isMobileValid(this.practice.phone)) {
        // this.errorHandler.handleError("Invalid Phone")
        return
      }
    }
    for (const division of this.divisions) {
      if (!division.name) {
        return
      }
      if (!division.address.city) {
        return
      }
      if (!division.address.state) {
        return
      }
      if (!division.meta.email) {
        return
      }
      if (!division.meta.email) {
        if (this.validator.isEmailValid(division.meta.email)) {
          return
        }
      }
      if (!division.meta.phone) {
        return
      } else {
        division.meta.phone = division.meta.phone.replace(/-/g, "")
      }
      if (division.meta.phone.length != 10) {
        return
      }
    }

    if (this.practice.meta.fax) {
      this.practice.meta.fax = this.practice.meta.fax.replace(/-/g, "")
    }

    this.isProcessing = true
    this.updateDivisions().then(() => {
      this.organizationService.update(this.practice.id, this.practice).subscribe((item) => {
        this.practice = item
        this.practicePhoneNumberFormat(this.practice.phone)
        this.practiceFaxNumberFormat(this.practice.meta.fax)
        this.errorHandler.showInfo("Practice Updated")
        this.isProcessing = false
      })
    }).catch((err) => {
      this.practicePhoneNumberFormat(this.practice.phone)
      if (this.practice.meta.fax) {
        this.practiceFaxNumberFormat(this.practice.meta.fax)
      }
      this.isProcessing = false
      // this.errorHandler.handleError(err.message || err)
    });
  }

  addDivision() {
    this.divisions.push(new Division({ organization: this.practice }))
  }

  setContext() {
  }

  back() {
    this.navService.goto("/practice/list")
  }

  onlyNum(event) {
    if (isNaN(parseInt(event.key))) {
      return false;
    }
  }

  updateDivisions(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.divisions && this.divisions.length) {
        let i = this.divisions.length
        let index = 0
        for (const division of this.divisions) {
          if (!division.address.city) {
            reject("City is required in practice location")
            return
          }
          if (!division.meta.email) {
            reject("Email is required in practice location")
            return
          }
          if (division.meta.email) {
            if (this.validator.isEmailValid(division.meta.email)) {
              reject("Invalid email in practice location")
              return
            }
          }
          if (division.meta.phone) {
            if (division.meta.phone.length != 10) {
              reject("Invalid phone in practice location")
              return
            }
            // if (this.validator.isMobileValid(division.meta.phone)) {
            //   reject("Invalid phone in practice location")
            //   return
            // }
          }
          if (!division.meta.phone) {
            reject("Phone is required in practice location")
            return
          } else {
            division.meta.phone = division.meta.phone.replace(/-/g, "")
          }
          if (division.meta.phone && division.meta.phone.length != 10) {
            reject("Invalid phone in practice location")
            return
          }
          if (division.meta.fax) {
            division.meta.fax = division.meta.fax.replace(/-/g, "")
          }
          if (division.id) {
            this.divisionService.update(division.id, division).subscribe(() => {
              this.divisionPhoneNumberFormat(division.meta.phone, index)
              this.divisionFaxNumberFormat(division.meta.fax, index)
              index++
            }, err => {
              this.divisionPhoneNumberFormat(division.meta.phone, index)
              this.divisionFaxNumberFormat(division.meta.fax, index)
            })
            --i
          } else {
            division.name = division.address.city
            division.code = division.address.city + new Date().getTime()
            this.divisionService.create(division).subscribe(() => {
              this.divisionPhoneNumberFormat(division.meta.phone, index)
              this.divisionFaxNumberFormat(division.meta.fax, index)
              index++
            }, err => {
              this.divisionPhoneNumberFormat(division.meta.phone, index)
              this.divisionFaxNumberFormat(division.meta.fax, index)
            })
            --i
          }
        }
        if (i == 0) {
          resolve(true)
        }
      } else {
        resolve(true)
      }
    });
  };

  practiceFaxNumberFormat(mobile) {
    if (!mobile) {
      return
    }
    let phoneNumber = mobile
    let phoneNumberArray
    phoneNumber = phoneNumber.replace(/[^0-9]*/g, '')
    if (phoneNumber) {
      phoneNumberArray = phoneNumber.split('')
      phoneNumber = ''
      phoneNumberArray.forEach((item, i) => {
        if (i < 2) { phoneNumber = phoneNumber + item }
        if (i == 2) { phoneNumber = phoneNumber + item + '-' }
        if ((i < 5) && (i > 2)) { phoneNumber = phoneNumber + item }
        if (i == 5) { phoneNumber = phoneNumber + item + '-' }
        if (i > 5 && i < 11) { phoneNumber = phoneNumber + item }
      });
    }
    return this.practice.meta.fax = phoneNumber
  }

  practicePhoneNumberFormat(mobile) {
    if (!mobile) {
      return
    }
    let phoneNumber = mobile
    let phoneNumberArray
    phoneNumber = phoneNumber.replace(/[^0-9]*/g, '')
    if (phoneNumber) {
      phoneNumberArray = phoneNumber.split('')
      phoneNumber = ''
      phoneNumberArray.forEach((item, i) => {
        if (i < 2) { phoneNumber = phoneNumber + item }
        if (i == 2) { phoneNumber = phoneNumber + item + '-' }
        if ((i < 5) && (i > 2)) { phoneNumber = phoneNumber + item }
        if (i == 5) { phoneNumber = phoneNumber + item + '-' }
        if (i > 5 && i < 11) { phoneNumber = phoneNumber + item }
      });
    }
    return this.practice.phone = phoneNumber
  }

  divisionPhoneNumberFormat(mobile, index) {
    if (!mobile) {
      return
    }
    let phoneNumber = mobile
    let phoneNumberArray
    phoneNumber = phoneNumber.replace(/[^0-9]*/g, '')
    if (phoneNumber) {
      phoneNumberArray = phoneNumber.split('')
      phoneNumber = ''
      phoneNumberArray.forEach((item, i) => {
        if (i < 2) { phoneNumber = phoneNumber + item }
        if (i == 2) { phoneNumber = phoneNumber + item + '-' }
        if ((i < 5) && (i > 2)) { phoneNumber = phoneNumber + item }
        if (i == 5) { phoneNumber = phoneNumber + item + '-' }
        if (i > 5 && i < 11) { phoneNumber = phoneNumber + item }
      });
    }
    return this.divisions[index].meta.phone = phoneNumber
  }

  divisionFaxNumberFormat(mobile, index) {
    if (!mobile) {
      return
    }
    let phoneNumber = mobile
    let phoneNumberArray
    phoneNumber = phoneNumber.replace(/[^0-9]*/g, '')
    if (phoneNumber) {
      phoneNumberArray = phoneNumber.split('')
      phoneNumber = ''
      phoneNumberArray.forEach((item, i) => {
        if (i < 2) { phoneNumber = phoneNumber + item }
        if (i == 2) { phoneNumber = phoneNumber + item + '-' }
        if ((i < 5) && (i > 2)) { phoneNumber = phoneNumber + item }
        if (i == 5) { phoneNumber = phoneNumber + item + '-' }
        if (i > 5 && i < 11) { phoneNumber = phoneNumber + item }
      });
    }
    return this.divisions[index].meta.fax = phoneNumber
  }

  phoneNumberFormat(mobile) {
    if (!mobile) {
      return
    }
    let phoneNumber = mobile
    let phoneNumberArray
    phoneNumber = phoneNumber.replace(/[^0-9]*/g, '')
    if (phoneNumber) {
      phoneNumberArray = phoneNumber.split('')
      phoneNumber = ''
      phoneNumberArray.forEach((item, i) => {
        if (i < 2) { phoneNumber = phoneNumber + item }
        if (i == 2) { phoneNumber = phoneNumber + item + '-' }
        if ((i < 5) && (i > 2)) { phoneNumber = phoneNumber + item }
        if (i == 5) { phoneNumber = phoneNumber + item + '-' }
        if (i > 5 && i < 11) { phoneNumber = phoneNumber + item }
      });
    }
    return phoneNumber;
  }

}

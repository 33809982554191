import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatSnackBarModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { OaAuthModule } from '../open-age-ng/auth/oa-auth.module';
import { OaCommonModule } from '../open-age-ng/common/oa-common.module';
import { OaDriveModule } from '../open-age-ng/drive/oa-drive.module';
import { OaGatewayModule } from '../open-age-ng/gateway/oa-gateway.module';
import { OaInsightModule } from '../open-age-ng/insight/oa-insight.module';
import { CalenderDayDetailComponent } from '../open-age-ng/insight/widgets/calender-day-detail/calender-day-detail.component';
import { OaSendItModule } from '../open-age-ng/send-it/oa-send-it.module';
import { CodeDialogComponent } from './components/code-dialog/code-dialog.component';
import { ContextBarComponent } from './components/context-bar/context-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { QuickLinksComponent } from './components/quick-links/quick-links.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { PracticeModule } from '../practice/practice.module';
import { SharedModule } from '../shared/shared.module';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';

const components = [
  HeaderComponent,
  FooterComponent,
  SideNavComponent,
  ContextBarComponent,
  NotFoundComponent,
  ConfirmDialogComponent,
  CodeDialogComponent,
  SideBarComponent,
  QuickLinksComponent
];
const entryComponents = [
  ConfirmDialogComponent,
  CodeDialogComponent,
  CalenderDayDetailComponent
];
const services = [];
@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatToolbarModule,
    MatSnackBarModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    MatTooltipModule,
    MatSidenavModule,
    OaSendItModule,
    OaAuthModule,
    OaDriveModule,
    OaGatewayModule,
    OaCommonModule,
    RouterModule,
    OaInsightModule,
    PracticeModule,
    SharedModule
  ],
  exports: [...components],
  entryComponents: [...entryComponents],
  providers: [...services],

})
export class CoreModule { }

export class ReportParam {
  label?: string;
  key: string;
  required?: boolean;
  value?: any;
  valueKey?: string;
  valueLabel?: string;
  message?: string;
  group?: string;
  options?: [{
    label?: string
    value?: string
  }];
  control?: string;
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { GenericApi } from '~/app/open-age/core/services/generic-api';
import { Tenant } from '../../directory/models';
import { Link, Nav } from '../structures';

@Injectable({
  providedIn: 'root'
})
export class TenantService extends GenericApi<Tenant> {

  constructor(
    http: HttpClient
  ) {
    super(
      http,
      'directory', {
      collection: 'tenants',
    });
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IUser } from 'src/app/open-age/core/models/user.interface';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css']
})
export class AvatarComponent implements OnInit {

  @Input()
  view: 'text' | 'avatar' = 'avatar';
  @Input()
  user: IUser;

  @Input()
  url: string;

  @Input()
  type: 'button' | 'thumbnail' | 'box' = 'thumbnail';

  @Output()
  click: EventEmitter<any> = new EventEmitter();

  name: string;
  size = 40;

  style = {
    backgroundColor: '#ffffff',
    borderRadius: '50%',
    color: 'var(--default)',
    "box-shadow": '1px 1px 2px 2px #ccc'
  };

  constructor() { }

  ngOnInit() {
    if (this.user.profile) {
      if (this.user.profile.firstName) {
        this.name = this.user.profile.firstName;
        if (this.user.profile.lastName) {
          this.name = `${this.name} ${this.user.profile.lastName}`;
        }
      }
    }

    if (!this.name && this.user.code) {
      this.name = this.user.code;
    }

    if (!this.name && this.user.email) {
      this.name = this.user.email;
    }

    if (!this.name && this.user.phone) {
      this.name = this.user.phone;
    }
  }

  onClick() {
    this.click.emit();
  }

}

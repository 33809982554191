import { Profile } from '../../directory/models';

export class IUser {
  id: string;
  code: string;
  phone: string;
  email: string;
  profile: Profile;
  status: string;
  timeStamp: Date;
}

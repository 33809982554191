import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'auth-facebook-login-button',
  templateUrl: './facebook-login-button.component.html',
  styleUrls: ['./facebook-login-button.component.css'],
})
export class FacebookLoginButtonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

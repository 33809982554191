import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '~/app/core/components/confirm-dialog/confirm-dialog.component';
import { UxService } from '~/app/core/services';
import { StudentListBaseComponent } from '~/app/open-age/directory/components/student-list.base.component';
import { StudentService } from '~/app/open-age/directory/services';

@Component({
  selector: 'directory-student-list',
  templateUrl: './student-list.component.html',
  styleUrls: ['./student-list.component.css']
})
export class StudentListComponent extends StudentListBaseComponent implements OnInit {
  @Input()
  view: 'table' | 'list' | 'grid' = 'table';
  dialogRef: any;

  constructor(
    public dialog: MatDialog,
    private uxService: UxService,
    studentService: StudentService
  ) {
    super(studentService, uxService);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onRemove(item): void {
    this.uxService.onConfirm().subscribe(() => {
      this.remove(item);
      this.uxService.showInfo('Deleted');
    });
  }
  openDialog(item): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {

        this.remove(item);
        return this.uxService.showInfo('Deleted sucessfully');
      }
    });
  }
}

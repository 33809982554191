import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { WidgetBaseComponent } from '~/app/open-age/insight/components/widget.base.component';
import { UxService } from '../../../../core/services/ux.service';
import { ReportTypeService } from '../../../../open-age/insight/services/report-type.service';
import { WidgetDataService } from '../../../../open-age/insight/services/widget-data.service';

@Component({
  selector: 'insight-grid-widget',
  templateUrl: './grid-widget.component.html',
  styleUrls: ['./grid-widget.component.css']
})
export class GridWidgetComponent extends WidgetBaseComponent {

  headArray = [];
  constructor(api: WidgetDataService, reportTypeService: ReportTypeService, uxService: UxService) {
    super(api, reportTypeService, uxService);
  }
}

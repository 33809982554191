import { Component, Input, OnInit } from '@angular/core';
import { TemplateConfig } from '../../../open-age/send-it/models/template-config.model';
import { Template } from '../../../open-age/send-it/models/template.model';

@Component({
  selector: 'send-it-config-editor',
  templateUrl: './config-editor.component.html',
  styleUrls: ['./config-editor.component.css']
})
export class ConfigEditorComponent implements OnInit {

  @Input()
  config: TemplateConfig;

  size: string;

  constructor() { }

  ngOnInit() {
    if (!this.config) {
      this.config = new TemplateConfig({});
    }
  }

  addPage() {
    this.config.page = {
      orientation: 'portrait',
      border: {
        left: 2,
        right: 2,
        top: 2,
        bottom: 2,
      },
      height: 10,
      width: 5
    };
  }

  addTo() {
    this.config.to = {
      field: ''
    };
  }

  addEntity() {
    this.config.entity = {
      field: '',
      type: ''
    };
  }

  addModes() {
    this.config.modes = {
      email: false,
      sms: false,
      push: false
    };
  }
}

import { ModelBase } from '../../core/models';
import { Role } from '../../directory/models';
import { Profile } from '../../directory/models/profile.model';
import { Calendar, Category } from './calendar.model';
import { Organization } from './organization.model';

export class User extends ModelBase {

  id: string;
  phone: string;
  email: string;
  appointmentEmail: string;
  canScheduleClinical: boolean;
  profile: Profile;
  role: Role;
  status: string;
  timeStamp: Date;
  meta: any;
  organization: Organization
  calendars: {
    category: Category,
    calendar: Calendar,
    isActive: Boolean
  }[]
  agents: {
    user: User,
    calendars: {
      category: Category,
      calendar: Calendar,
      isActive: Boolean
    }[],
    grouped: any
  }[]

  constructor(obj?: any) {
    super()
    if (!obj) {
      return;
    }

    this.id = obj.id;
    this.timeStamp = obj.timeStamp;

    this.code = obj.code;
    this.name = obj.name;
    this.email = obj.email;
    this.appointmentEmail = obj.appointmentEmail;
    this.canScheduleClinical = obj.canScheduleClinical || false;
    this.phone = obj.phone;
    this.meta = obj.meta;

    if (obj.profile) {
      this.profile = new Profile(obj.profile);
    }

    if (obj.organization) {
      this.organization = new Organization(obj.organization);
    }

    this.calendars = []

    if (obj.calendars) {
      for (const item of obj.calendars) {
        this.calendars.push({
          category: new Category(item.category),
          calendar: new Calendar(item.calendar),
          isActive: item.isActive
        })
      }
    }

    if (obj.role) {
      this.role = new Role(obj.role);
    }

    this.agents = []

    if (obj.agents) {
      for (const item of obj.agents) {
        let calendars = []
        let grouped = {}
        if (item.calendars) {
          for (const calendarItem of item.calendars) {
            calendars.push({
              category: new Category(calendarItem.category),
              calendar: new Calendar(calendarItem.calendar),
              isActive: calendarItem.isActive
            })
            if (grouped[calendarItem.category.name] && grouped[calendarItem.category.name].calendars) {
              grouped[calendarItem.category.name].calendars.push({
                category: new Category(calendarItem.category),
                calendar: new Calendar(calendarItem.calendar),
                isActive: calendarItem.isActive
              })
            } else {
              grouped[calendarItem.category.name] = {
                name: calendarItem.category.name,
                calendars: [{
                  category: new Category(calendarItem.category),
                  calendar: new Calendar(calendarItem.calendar),
                  isActive: calendarItem.isActive
                }]
              }
            }
          }
        }
        this.agents.push({
          user: new User(item.user),
          calendars: calendars,
          grouped: grouped
        })
      }
    }

  }
}

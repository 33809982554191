import { Entity } from '../../core/models/entity.model';
import { ModelBase } from '../../core/models/model-base.model';
import { User } from './user.model';

export class Journal extends ModelBase {

  message: string;
  entity: Entity;
  user: User;
  meta: {} = {};
  type: string;
  changes: [{
    field: string,
    value: string,
    oldValue: string
  }];

  constructor(obj?: any) {
    super();

    if (!obj) { return; }
    this.id = obj.id;
    this.timeStamp = obj.timeStamp;

    this.message = obj.message;
    this.meta = obj.meta;
    this.type = obj.type;

    this.changes = obj.changes;

    if (obj.entity) {
      this.entity = new Entity(obj.entity);
    }
  }
}

import { Component, ErrorHandler, Input, OnDestroy, OnInit } from '@angular/core';
import { TemplateService } from 'src/app/open-age/send-it/services';
import { PagerModel } from '~/app/open-age/core/structures';
import { Template } from '../models';

export class TemplateListBaseComponent extends PagerModel<Template> implements OnInit, OnDestroy {

  @Input()
  view: 'table' | 'list' | 'grid' = 'table';

  @Input()
  level: string;

  constructor(
    private api: TemplateService,
    errorHandler: ErrorHandler
  ) {
    super({ api, errorHandler, filters: ['level'] });
  }
  ngOnInit(): void {

    this.filters.properties['level'].value = this.level;
    this.fetch();
  }

  ngOnDestroy(): void {
  }
}

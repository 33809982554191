import { Component } from '@angular/core';
import { UxService } from '~/app/core/services';
import { LogDetailBaseComponent } from '~/app/open-age/insight/components/log-detail-base.component';
import { LogService } from '~/app/open-age/insight/services/log.service';

@Component({
  selector: 'insight-log-details',
  templateUrl: './log-details.component.html',
  styleUrls: ['./log-details.component.css']
})

export class LogDetailsComponent extends LogDetailBaseComponent {

  constructor(
    service: LogService,
    uxService: UxService
  ) {
    super(service, uxService);
  }

  ngOnInit() {
  }
}
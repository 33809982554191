import { Injectable } from '@angular/core';
import { TelepainApiBase } from './telepain-api.base';
import { HttpClient } from '@angular/common/http';
import { RoleService } from '../../core/services';
import { UxService } from '~/app/core/services';
import { Practice } from './../models/practice';
import { PracticeSurveyCategory } from '../models/practice-survey-category';


@Injectable({
  providedIn: 'root'
})
export class PracticeService extends TelepainApiBase<Practice>{

  surveyCategory: TelepainApiBase<PracticeSurveyCategory>;

  constructor(
    http: HttpClient,
    roleService: RoleService,
    uxService: UxService) {
    super('practices', http, roleService, uxService);
    this.surveyCategory = new TelepainApiBase<PracticeSurveyCategory>(`practices/survey-category`, http, roleService, uxService);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Address } from '~/app/open-age/core/models';
import { WizStepBaseComponent } from '~/app/open-age/core/structures/wiz/wiz-step-base.component';
import { Employee, Profile } from '~/app/open-age/directory/models';
import { EmployeeService } from '~/app/open-age/directory/services';

@Component({
  selector: 'directory-address-editor',
  templateUrl: './address-editor.component.html',
  styleUrls: ['./address-editor.component.css']
})
export class AddressEditorComponent extends WizStepBaseComponent implements OnInit {

  @Input()
  address: Address;

  @Input()
  employee: Employee;

  @Input()
  usercode: string;

  @Input()
  readonly: string;

  constructor(private api: EmployeeService) {
    super();
  }

  ngOnInit() {
  }

  validate(): boolean {
    // if (this.usercode !== 'my') {
    //   if (!this.address.line1) {
    //     return false;
    //   }
    //   if (!this.address.city) {
    //     return false;
    //   }
    //   if (!this.address.state) {
    //     return false;
    //   }
    //   if (!this.address.district) {
    //     return false;
    //   }
    //   if (!this.address.pinCode) {
    //     return false;
    //   }
    // }
    return true;
  }

  complete(): Observable<any> | boolean {
    if (!this.employee.type) {
      this.employee.type = 'normal';
    }
    if (!this.employee.status) {
      this.employee.status = 'active';
    }
    // TODO: add defaults
    if (this.readonly) {
      return this.api.get(this.employee.id);
    }
    if (this.employee.id) {
      return this.api.update(this.employee.id, this.employee);
    } else {
      return this.api.create(this.employee);
    }
  }
}

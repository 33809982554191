import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PracticesComponent } from './practices/practices.component';
import { PracticeNewComponent } from './practice-new/practice-new.component';
import { PracticeComponent } from './practice/practice.component';
import { PracticeViewComponent } from './practice-view/practice-view.component';

const routes: Routes = [{
  path: 'list',
  component: PracticesComponent,
  children: [{
    path: 'new',
    component: PracticeNewComponent,
  },
  {
    path: 'view/:id',
    component: PracticeViewComponent,
  },
  {
    path: ':id',
    component: PracticeComponent,
  }]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PracticeRoutingModule { }

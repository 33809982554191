import { Organization } from '../../directory/models';

export class Entity {
  id: string | number;
  code: string;
  type: string;
  name: string;
  organization: Organization;
  timeStamp: Date;
  isSelected = false;
  isProcessing = false;
  isDeleted = false;

  constructor(obj?: any, type?: string, organization?: any) {
    if (!obj) { return; }

    this.id = obj.id;
    this.code = obj.code;
    this.type = type ? type : obj.type;
    this.name = obj.name;
    this.timeStamp = obj.timeStamp;
    if (organization) {
      this.organization = new Organization(organization);
    } else if (obj.organization) {
      this.organization = new Organization(obj.organization);
    }
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoleService } from 'src/app/open-age/core/services';
import { Role } from 'src/app/open-age/directory/models';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: RoleService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    // if (next.url && next.url[0] && next.url[0].path === 'errors') {
    //   return true;
    // }

    const roleToken = next.queryParams['access_token'];

    const currentRole = this.auth.currentRole();

    if ((!currentRole && roleToken) || (currentRole && roleToken && currentRole.token !== roleToken)) {
      return this.auth.setRoleKey(roleToken).pipe(map((role) => {
        return this.roleCheck(role, next);
      }));
    }

    return this.roleCheck(currentRole, next);
  }

  roleCheck(role: Role, next: ActivatedRouteSnapshot) {
    if (!role || !role.token) {
      // this.router.navigate(['/', 'home']);
      return false;
    }

    // if (!role.organization) {
    //   this.router.navigate(['/', 'profile']);
    //   return false;
    // }

    if (!next.data['permissions']) {
      return true;
    }

    const permissions = next.data['permissions'] as string[];

    const canAccess = this.auth.hasPermission(permissions);

    if (!canAccess) {
      console.error(`you don't have any of of the permission: ${permissions}`);
    }

    return canAccess;
  }
}

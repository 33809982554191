import { Component, OnInit } from '@angular/core';
import { UxService } from 'src/app/core/services/ux.service';
import { JobListBaseComponent } from 'src/app/open-age/send-it/components/job-list.base.components';
import { JobService } from '../../../open-age/send-it/services/job.service';

@Component({
  selector: 'send-it-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.css']
})
export class JobListComponent extends JobListBaseComponent {

  constructor(
    private api: JobService,
    private uxService: UxService
  ) {
    super(api, uxService);
  }

}

import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Role } from 'src/app/open-age/directory/models';
import { RoleService } from '../../../open-age/core/services/role.service';

@Component({
  selector: 'auth-current-role',
  templateUrl: './current-role.component.html',
  styleUrls: ['./current-role.component.css'],
})
export class CurrentRoleComponent implements OnInit {

  @Input()
  click: EventEmitter<void> = new EventEmitter();

  role: Role;
  url: string;

  constructor(
    private auth: RoleService
  ) {
    auth.roleChanges.subscribe((role) => {
      this.role = role;
    });
  }

  ngOnInit() {
    this.role = this.auth.currentRole();
    if (this.role.student || this.role.employee) {
      const profile = this.role.student ? this.role.student.profile : null || this.role.employee ? this.role.employee.profile : null;
      if (profile && profile.pic && (profile.pic.url || profile.pic.thumbnail)) {
        this.url = profile.pic.thumbnail || profile.pic.url;
      }
    } else {
      this.url = this.role.profile.pic.thumbnail || this.role.profile.pic.url
    }
  }

  onClick() {
    this.click.emit();
  }

  getLogo(): string {
    if (this.role.organization.logo && this.role.organization.logo.url) {
      return this.role.organization.logo.url;
    } else if (this.role.organization.type === 'provider') {
      return '../../../../assets/icons/AMB.png';
    } else if (this.role.organization.type === 'hospital') {
      return '../../../../assets/icons/Novant.png';
    }
  }

}

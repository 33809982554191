import { ModelBase } from '../../core/models';
import { Task } from './task.model';
import { User } from './user.model';

export class TimeLog extends ModelBase {
  comment: string;
  activity: string;

  date: Date;
  hours: number;
  user: User;
  task: Task;

  constructor(obj?: any) {
    super(obj);
    if (!obj) { return; }

    this.comment = obj.comment;
    this.activity = obj.activity;
    this.date = obj.date;
    this.hours = obj.hours;

    if (obj.user) { this.user = new User(obj.user); }
    if (obj.task) { this.task = new Task(obj.task); }
  }
}

import { Component, OnInit } from '@angular/core';
import { UxService } from '~/app/core/services/ux.service';
import { NewFolderBaseComponent } from '~/app/open-age/drive/components/new-folder-base.component';
import { FolderService } from '~/app/open-age/drive/services';

@Component({
  selector: 'drive-new-folder',
  templateUrl: './new-folder.component.html',
  styleUrls: ['./new-folder.component.css']
})
export class NewFolderComponent extends NewFolderBaseComponent {

  constructor(
    service: FolderService,
    uxService: UxService
  ) {
    super(service, uxService);
  }

}

import { Component, OnInit } from '@angular/core';
import { UxService } from '~/app/core/services';
import { ComponentTypeDetailBaseComponent } from '~/app/open-age/car/components/component-type-detail-base.component';
import { ComponentTypeService } from '~/app/open-age/car/services';

@Component({
  selector: 'car-component-type-editor',
  templateUrl: './component-type-editor.component.html',
  styleUrls: ['./component-type-editor.component.css']
})
export class ComponentTypeEditorComponent extends ComponentTypeDetailBaseComponent {

  constructor(
    service: ComponentTypeService,
    uxService: UxService
  ) {
    super(service, uxService);
  }
}

import { Address } from './../../core/models/address.model';
import { ModelBase } from './../../core/models/model-base.model';

export class Practice extends ModelBase {

    name: string;
    code: string;

    status: string;

    phone: string;
    email: string;
    fax: string;

    address: Address;

    EIN: string;
    constructor(obj?: any) {
        super();

        if (!obj) {
            return;
        }

        this.id = obj.id;
        this.code = obj.code;
        // this.logo = new Pic(obj.logo);
        this.name = obj.name;
        this.status = obj.status;
        this.timeStamp = obj.timeStamp;
        this.email = obj.email;
        this.phone = obj.phone;
        this.address = new Address(obj.address || obj);
    }
}

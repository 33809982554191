import { ErrorHandler, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { RoleService } from '~/app/open-age/core/services';
import { IInputValidator } from '~/app/open-age/core/services/input-validator.interface';
import { Role, User } from '~/app/open-age/directory/models';

export class LoginPasswordBaseComponent implements OnInit, OnChanges {

  @Output()
  success: EventEmitter<Role> = new EventEmitter();

  @Output()
  failure: EventEmitter<Error> = new EventEmitter();

  @Input()
  user: User;

  idType = 'email';

  code: string;
  email: string;
  mobile: string;
  rollNo: string;
  employeeNo: string;
  password = '';
  isProcessing = false;

  error: string;

  employeeNoError: string;
  rollNoError: string;
  mobileError: string;
  emailError: string;
  codeError: string;

  constructor(
    private auth: RoleService,
    private errorHandler: ErrorHandler,
    private validator: IInputValidator
  ) { }

  ngOnChanges(): void {
    if (this.user) {
      this.code = this.user.code;
      this.mobile = this.user.phone;
      this.email = this.user.email;
    }
  }

  ngOnInit() { }

  validateEmail() {
    if (this.email) {
      this.code = null;
      this.mobile = null;
      this.employeeNo = null;
      this.rollNo = null;
    } else {
      return;
    }
    this.emailError = this.validator.isEmailValid(this.email);
    if (this.emailError) {
      return;
    }
    this.isProcessing = true;
    this.auth.exists(this.email, 'email').subscribe((result) => {
      this.isProcessing = false;

      if (result.exists) {
        this.emailError = '';
        return;
      }

      const error = new Error();

      if (!result.exists) {
        error.name = 'EMAIL_INVALID';
        error.message = `${this.email || 'email'} does not exist`;
      } else if (result.login && !result.login.password) {
        error.name = 'PASSWORD_NEED_RESET';
        error.message = `You need to reset your password`;
      }

      if (error.name) {
        this.emailError = error.message;
        this.failure.next(error);
      }
    });
  }

  validateMobile() {
    if (this.mobile) {
      this.code = null;
      this.email = null;
      this.employeeNo = null;
      this.rollNo = null;
    } else {
      return;
    }
    this.mobileError = this.validator.isMobileValid(this.mobile);
    if (this.mobileError) {
      return;
    }
    this.isProcessing = true;
    this.auth.exists(this.mobile, 'mobile').subscribe((exist) => {
      if (exist) {
        this.mobileError = '';
      } else {
        this.mobileError = `${this.mobile || 'mobile no'} does not exist`;
      }
      this.isProcessing = false;
    });
  }
  validateRollNo() {
    if (this.rollNo) {
      this.code = null;
      this.email = null;
      this.employeeNo = null;
      this.mobile = null;
    } else {
      return;
    }
    this.rollNoError = undefined;
    this.auth.exists(this.rollNo, 'student-code').subscribe((result) => {
      if (result.exists) {
        this.rollNoError = '';
      } else {
        this.rollNoError = `${this.rollNo || 'roll no'} is taken`;
      }
    });
  }

  validateEmployeeNo() {
    if (this.employeeNo) {
      this.code = null;
      this.email = null;
      this.rollNo = null;
      this.mobile = null;
    } else {
      return;
    }
    this.employeeNoError = undefined;
    this.auth.exists(this.employeeNo, 'employee-code').subscribe((result) => {
      this.isProcessing = false;
      if (result.exists) {
        this.employeeNoError = '';
        return;
      } else {
        this.employeeNoError = 'Employee No Does not Exist';
        return;
      }
    });
  }

  validateCode() {
    if (this.code) {
      this.employeeNo = null;
      this.email = null;
      this.rollNo = null;
      this.mobile = null;
    } else {
      return;
    }
    if (this.code) {
      if (!this.validator.isMobileValid(this.code)) {
        this.mobile = this.code;
        this.validateMobile();
        return;
      } else if (!this.validator.isEmailValid(this.code)) {
        this.email = this.code;
        this.validateEmail();
        return;
      }
    }
    this.codeError = undefined;
    this.isProcessing = true;

    this.auth.exists(this.code, 'code').subscribe((exist) => {
      if (exist) {
        this.codeError = ``;
      } else {
        this.codeError = `${this.code || 'code'} does not exist`;
      }
      this.isProcessing = false;
    });
  }

  confirm() {
    this.auth.verifyPassword(this.email, this.mobile, this.code, this.employeeNo, this.rollNo, this.password).subscribe((role: Role) => {
      this.success.next(role);
    }, (err) => {
      const error = new Error();
      error.name = err.message;
      error.message = err.message;

      if (error.name === 'PASSWORD_INVALID') {
        error.message = 'wrong password';
      }

      this.error = error.message;

      this.failure.next(error);
      this.errorHandler.handleError(error);
    });
  }
}

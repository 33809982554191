import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material';
import { debounceTime, switchMap } from 'rxjs/operators';
import { Designation } from '../../../open-age/directory/models/designation.model';
import { DesignationService } from '../../../open-age/directory/services/designation.service';

@Component({
  selector: 'directory-designation-picker',
  templateUrl: './designation-picker.component.html',
  styleUrls: ['./designation-picker.component.css'],
})
export class DesignationPickerComponent implements OnInit, OnChanges {

  @Input()
  designation: Designation;

  @Input()
  placeholder: string;

  @Input()
  usercode: string;

  @Input()
  reset = false;

  @Input()
  readonly = false;

  @Input()
  required = false;

  @Output()
  changed: EventEmitter<Designation> = new EventEmitter();

  @ViewChild('userInput')
  userInput: ElementRef<HTMLInputElement>;

  @ViewChild('designationPicker')
  matAutocomplete: MatAutocomplete;

  control = new FormControl();

  isEditing = false;

  items: Designation[];

  constructor(
    private employeeService: DesignationService
  ) {

    this.control.valueChanges.pipe(
      debounceTime(300),
      switchMap((value) => this.employeeService.search({ name: value }))
    ).subscribe((page) => {
      this.items = page.items;
    });
  }

  ngOnInit() {
    this.userInput.nativeElement.value = this.displayEmployee(this.designation);
  }

  ngOnChanges() {
    if (this.usercode === 'my') {
      this.readonly = true;
      this.required = false;
    }
    if (this.reset) {
      this.designation = null;
    }
  }

  displayEmployee(department?: Designation): string | undefined {
    if (!department) {
      return '';
    }

    if (typeof department === 'string') {
      return department;
    }

    if (!department.name || !department.name) {
      return department.code;
    }

    return `${department.name}`;
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.designation = event.option.value;

    this.changed.emit(this.designation);

    this.userInput.nativeElement.value = this.displayEmployee(this.designation);
    this.control.setValue(null);
  }
}

import { ModelBase } from '../../core/models/model-base.model';

export class CuresEntity extends ModelBase {

    cures_id: string
    line1: string
    city: string
    state: string
    code: string

    constructor(obj?: any) {
        super();

        if (!obj) {
            return;
        }

        this.id = obj.id
        this.cures_id = obj.cures_id
        this.line1 = obj.line1
        this.city = obj.city
        this.state = obj.state
        this.code = obj.code
    }
}

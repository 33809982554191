import { Component, OnInit } from '@angular/core';
import { UxService } from 'src/app/core/services/ux.service';
import { ProjectListBaseComponent } from 'src/app/open-age/gateway/components/project-list.base.component';
import { ProjectService } from 'src/app/open-age/gateway/services/project.service';

@Component({
  selector: 'gateway-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.css']
})
export class ProjectListComponent extends ProjectListBaseComponent {
  constructor(
    projectService: ProjectService,
    private uxService: UxService
  ) {
    super(projectService, uxService);
  }
  onRemove(item): void {
    this.uxService.onConfirm().subscribe(() => {
      this.remove(item);
      this.uxService.showInfo('Deleted');
    });
  }
}

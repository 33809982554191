import { Component, Input, OnInit } from '@angular/core';
import { AnalyticsBaseComponent } from 'src/app/open-age/insight/components/analytics.base.component';
import { StatGridService } from 'src/app/open-age/insight/services/stat-grid.service';
import { Link } from '~/app/open-age/core/structures';
import { UxService } from '../../../core/services/ux.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'insight-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css']
})
export class AnalyticsComponent extends AnalyticsBaseComponent {
  @Input()
  view: string;

  @Input()
  links: Link[] = [];

  @Input()
  noOfColumns = 'two';

  constructor(
    statService: StatGridService,
    uxService: UxService
  ) {
    super(statService, uxService);
  }

}
